import styled from "@emotion/styled";
import {
	IconButton,
	ListItemButton,
	SwipeableDrawerProps,
	useMediaQuery,
	Box,
	Typography,
	SwipeableDrawer,
} from "@mui/material";
import {useViewController} from "data/services/locator/locator_hook.service";
import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {Exist} from "views/components/exist/exist.component";
import {IHeaderController} from "views/components/header/header.controller";
import {ReactComponent as IconMenu} from "assets/img/icons/Menu.svg";
import {ReactComponent as IconHelp} from "assets/img/icons/Help.svg";
import {Container} from "views/components/structure/structure.component";
import {Bindings} from "data/constants/bindings";
// import {ReactComponent as Logo} from "assets/img/logo.svg";
// import logoMobile from "assets/img/logoMobile.png";
import Logo from "assets/img/ppa-logo-white.png";

import {useLocation} from "react-router";
import {IAuthController} from "views/controllers/auth/auth.controller";
import {PagePreloader} from "views/components/preloader";

const mobileViewQuery = `max-width: 970px`;

const HeaderWrapper = styled.div`
	background: #0c2b44;
	font-family: var(--fontFamilySecond);
	color: #bebebe;
	font-weight: 500;
	font-size: 20px;
	line-height: 20px;
	border-bottom: 1px solid #4dc1ef;
	.MuiModal-backdrop {
		display: none;
		z-index: -1 !important;
		opacity: 0 !important;
	}
`;

const Wrapper = styled(Container)`
	display: flex;
	justify-content: flex-start;
	height: 80px;

	@media (${mobileViewQuery}) {
		padding: 8px 16px;
		justify-content: space-between;
		height: 60px;
	}
`;

const GameLogoLink = styled(NavLink)`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 20px;
	flex-direction: column;

	img {
		display: block;
		width: 85px;

		@media (${mobileViewQuery}) {
			width: 60px;
		}
	}

	p {
		color: #ffffff;
		font-weight: 700;
		margin-top: 3px;
		letter-spacing: 2px;

		@media (${mobileViewQuery}) {
			font-size: 11px;
		}
	}
`;

const MenuList = styled.div`
	display: flex;
	margin-left: 100px;

	a {
		display: flex;
		align-items: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px;
		font-family: var(--fontFamilySecond);
		color: #fff;
		height: 100%;
		padding: 0 30px;

		&.active,
		&:hover {
			color: #0c2b44;
			background: #ffffff;
		}
	}
`;

const LogInButton = styled.button`
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	font-family: var(--fontFamilySecond);
	outline: none;
	border: none;
	background: none;
	color: #fff;
	height: 100%;
	padding: 0 30px;
	&.active,
	&:hover {
		color: #0c2b44;
		background: #ffffff;
	}
`;

const Menu = styled.menu`
	margin-right: auto;
	display: flex;
	flex: 1;
`;

const IconMenuButton = styled(IconButton)`
	height: 60px;
	margin-right: 0;
	padding: 0;

	svg {
		height: 100%;
	}
`;

const BoxWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	height: 100%;
	background: #0c2b44;
	flex-direction: column;
`;

const ListFull = styled.div`
	width: 100%;

	a {
		&.active,
		&:hover {
			color: #0c2b44;
			background: #ffffff;
		}
	}
`;

const Positioned = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	display: none;
	flex-flow: column;
	background: var(--background3);
	color: var(--textColor7);
	box-shadow: 0 0.1px 0.3px rgba(28, 28, 30, 0.1), 0 1px 2px rgba(28, 28, 30, 0.2);
	border-radius: 0 0 4px 4px;
	width: max-content;
	z-index: 1;

	a {
		white-space: nowrap;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		opacity: 1;
		padding: 8px 12px;
		color: var(--textColor7);

		&:last-child {
			border: none;
		}

		&:hover {
			color: var(--textColor7);
		}

		&.active {
			color: var(--textColor7);
		}
	}

	& > div {
		border-bottom: 1px solid #cccccc;

		&:last-child {
			border-bottom: none;
		}
	}
`;

const RelativeBox = styled(Box)`
	position: relative;
	display: flex;
	align-items: center;

	a {
		white-space: nowrap;
	}

	&:hover {
		.sub-menu {
			display: flex;
		}
	}
`;

const Submenu = styled.div`
	padding-left: 20px;
`;

const LinkSpan = styled(Typography)`
	line-height: 32px;
	margin-left: 20px;
`;

const MobileNav = styled.div`
	display: flex;
	align-items: center;

	a {
		margin-right: 20px;
	}
`;

/**
 * Override type casting because of outdated versions of React
 */
const Drawer = SwipeableDrawer as unknown as React.FC<SwipeableDrawerProps>;

export const Header: React.FC = observer(() => {
	const isMobileView = useMediaQuery(`(${mobileViewQuery})`);
	const {isAuthorized} = useViewController<IAuthController>(Bindings.AuthController);
	const {i18n, staticPagesMenuList, mainMenuList, rootPath, goToSSO, showPreloader} =
		useViewController<IHeaderController>(Bindings.HeaderController);
	const [isOpen, toggle] = useState(false);
	const location = useLocation();
	const [oldLoc, setLoc] = useState(location.pathname);

	const showDrawer = () => {
		toggle(true);
	};
	const hideDrawer = () => {
		toggle(false);
	};

	useEffect(() => {
		if (location.pathname !== oldLoc) {
			setLoc(location.pathname);
			if (isOpen) {
				toggle(false);
			}
		}
	}, [location, oldLoc, isOpen]);

	return showPreloader ? (
		<PagePreloader />
	) : (
		<HeaderWrapper>
			<Wrapper>
				<Exist when={isMobileView}>
					<Drawer
						transitionDuration={{exit: 0}}
						anchor={"left"}
						open={isOpen}
						onClose={hideDrawer}
						onOpen={showDrawer}
						PaperProps={{
							sx: {
								width: "80%",
								color: "#FFF",
								background: "var(--background2)",
							},
						}}>
						<BoxWrapper>
							<ListFull>
								{mainMenuList.map((item, index) => (
									<div key={index}>
										<ListItemButton component={NavLink} to={item.url}>
											<LinkSpan>{item.label}</LinkSpan>
										</ListItemButton>
										<Exist when={item.children}>
											<Submenu>
												{item.children?.map((subMenuItem) => (
													<ListItemButton
														component={NavLink}
														to={subMenuItem.url}
														key={subMenuItem.url}>
														<LinkSpan>{subMenuItem.label}</LinkSpan>
													</ListItemButton>
												))}
											</Submenu>
										</Exist>
									</div>
								))}
								{staticPagesMenuList.map(({label, url}, index) => (
									<ListItemButton key={index} component={NavLink} to={url}>
										<LinkSpan>{label}</LinkSpan>
									</ListItemButton>
								))}
								<Exist when={isAuthorized}>
									<ListItemButton component={NavLink} to="/my-account">
										<LinkSpan>
											{i18n.t("nav.main.my_account_slam", "My Account")}
										</LinkSpan>
									</ListItemButton>
								</Exist>
							</ListFull>
						</BoxWrapper>
					</Drawer>
				</Exist>
				<GameLogoLink to={rootPath}>
					<img src={Logo} alt={"Pick Em"} />
					<p>PICK 'EM</p>
				</GameLogoLink>
				<Exist when={isMobileView}>
					<MobileNav>
						<Exist when={!isAuthorized}>
							<LogInButton onClick={goToSSO}>
								{i18n.t("nav.main.log_in", "Log in")}
							</LogInButton>
						</Exist>
						<NavLink to={"/help"}>
							<IconHelp />
						</NavLink>
						<IconMenuButton
							color="inherit"
							aria-label="open drawer"
							onClick={showDrawer}
							edge="end">
							<IconMenu />
						</IconMenuButton>
					</MobileNav>
				</Exist>
				<Exist when={!isMobileView}>
					<Menu>
						<MenuList>
							{mainMenuList.map((item, index) => (
								<RelativeBox key={index}>
									<NavLink to={item.url}>{item.label}</NavLink>
									<Exist when={item.children}>
										<Positioned className={"sub-menu"}>
											{item.children?.map((it, index) => (
												<ListItemButton
													component={NavLink}
													to={it.url}
													key={index}>
													{it.label}
												</ListItemButton>
											))}
										</Positioned>
									</Exist>
								</RelativeBox>
							))}
							{staticPagesMenuList.map(({label, url}, index) => (
								<RelativeBox key={index}>
									<NavLink to={url}>{label}</NavLink>
								</RelativeBox>
							))}
						</MenuList>
					</Menu>
					<MenuList>
						<Exist when={!isAuthorized}>
							<LogInButton onClick={goToSSO}>
								{i18n.t("nav.main.log_in", "Log in")}
							</LogInButton>
						</Exist>
						<Exist when={isAuthorized}>
							<NavLink to={"/my-account"}>
								{i18n.t("nav.main.my_account", "My Account")}
							</NavLink>
						</Exist>
					</MenuList>
				</Exist>
			</Wrapper>
		</HeaderWrapper>
	);
});
