import React, {lazy, Fragment} from "react";
import {Route} from "react-router-dom";
import {ContestRoot} from "views/components/contest/contest_root/contest_root.component";
import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";
import {MainLayout} from "views/components/layouts/main_layout.component";

const MyPicks = lazy(retryFailLoad(() => import("views/pages/my_picks/my_picks.page")));
const ResetPassword = lazy(
	retryFailLoad(() => import("views/pages/reset_password/reset_password.page"))
);
const MyAccount = lazy(retryFailLoad(() => import("views/pages/my_account/my_account.page")));
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));
const ComponentsReview = lazy(
	retryFailLoad(() => import("views/pages/components_review/components_review.page"))
);
const Contest = lazy(retryFailLoad(() => import("views/pages/contest/contest.page")));
const ContestResult = lazy(
	retryFailLoad(() => import("views/pages/contest_result/contest_result.page"))
);
const Backdoor = lazy(() => import("views/pages/backdoor/backdoor.page"));
const Standings = lazy(retryFailLoad(() => import("views/pages/standings/standings.page")));

export const RootRoutes = (
	<Fragment>
		<Route element={<MainLayout />}>
			<Route index element={<MyPicks />} />
			<Route element={<NotAuthOnlyRoute />}>
				<Route path="/reset-password" element={<ResetPassword />} />
				<Route path="/backdoor" element={<Backdoor />} />
			</Route>
			<Route element={<PrivateRoute />}>
				<Route path="rankings">
					<Route index element={<Standings />} />
					<Route path=":contestID" element={<Standings />} />
				</Route>
				<Route path="/my-account" element={<MyAccount />} />
			</Route>
			<Route path="contest/:id" element={<ContestRoot />}>
				<Route index element={<Contest />} />
				<Route path="result" element={<ContestResult />} />
			</Route>
			<Route path="components" element={<ComponentsReview />} />
			<Route path="help/*" element={<Help />} />
			<Route path="*" element={<NotFound />} />
		</Route>
	</Fragment>
);

export default RootRoutes;
