// import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {first} from "lodash";
import {action, makeAutoObservable, observable} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {ModalType, RequestState} from "data/enums";

interface IMenuItem {
	url: string;
	label: string;
	children?: {url: string; label: string}[];
}

export interface IHeaderController extends ViewController {
	get isAuthorized(): boolean;

	get rootPath(): string;

	get i18n(): ILocalizationStore;

	get isOpenDrawer(): boolean;

	get userInitials(): string;

	get userFullName(): string;

	get mainMenuList(): IMenuItem[];

	get staticPagesMenuList(): IMenuItem[];

	get showPreloader(): boolean;

	toggleDrawer(value: boolean): void;

	openLoginModal(): void;

	openRegistrationModal(): void;

	goToSSO(): void;
}

@injectable()
export class HeaderController implements IHeaderController {
	@observable public _drawerState: boolean = false;
	@observable _requestState: RequestState = RequestState.IDLE;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	get rootPath(): string {
		return "/";
	}

	get userInitials(): string {
		const firstName = first(this._userStore.user?.firstName) || "";
		const lastName = first(this._userStore.user?.lastName) || "";

		return `${firstName}${lastName}`.toUpperCase();
	}

	get userFullName(): string {
		const firstName = this._userStore.user?.firstName || "";
		const lastName = this._userStore.user?.lastName || "";

		return `${firstName} ${lastName}`;
	}

	get isOpenDrawer(): boolean {
		return this._drawerState;
	}

	get mainMenuList() {
		const menu = [
			{
				url: this.rootPath,
				label: this.i18n.t("nav.main.my_picks", "My Picks"),
			},
		];

		if (this.isAuthorized) {
			menu.push({
				url: "rankings",
				label: this.i18n.t("nav.main.standings", "Rankings"),
			});
		}

		return menu;
	}

	get staticPagesMenuList() {
		return [
			{
				url: "/help",
				label: this.i18n.t("nav.main.help", "Help"),
			},
		];
	}

	get showPreloader() {
		return this._requestState === RequestState.PENDING;
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: error.message,
		});
	};

	@action
	toggleDrawer(value: boolean): void {
		this._drawerState = value;
	}

	@action
	goToSSO = () => {
		this._requestState = RequestState.PENDING;
		void this._userStore.goToSSO().catch(this.onError);
	};

	openLoginModal(): void {
		// this._modalsStore.showModal(ModalType.LOGIN);
	}

	openRegistrationModal(): void {
		// this._modalsStore.showModal(ModalType.REGISTRATION);
	}

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}
}
