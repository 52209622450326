export const Bindings = {
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	UserApiProvider: Symbol("UserApiProvider"),
	LeaguesApiProvider: Symbol("LeaguesApiProvider"),
	PasswordApiProvider: Symbol("PasswordApiProvider"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	CountriesStore: Symbol("CountriesStore"),
	RoundsStore: Symbol("RoundsStore"),
	PlayersStore: Symbol("PlayersStore"),
	SquadsStore: Symbol("SquadsStore"),
	UserStore: Symbol("UserStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	AuthController: Symbol("AuthController"),
	LoginController: Symbol("LoginController"),
	MyAccountController: Symbol("MyAccountController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	ChecksumStore: Symbol("ChecksumStore"),
	LiveScoreController: Symbol("LiveScoreController"),
	ResetPasswordController: Symbol("ResetPasswordController"),
	SessionController: Symbol("SessionController"),
	BootstrapController: Symbol("BootstrapController"),
	LeaguesStore: Symbol("LeaguesStore"),
	MyLeaguesController: Symbol("MyLeaguesController"),
	JoinLeaguesController: Symbol("JoinLeaguesController"),
	CreateLeagueController: Symbol("CreateLeagueController"),
	LeagueController: Symbol("LeagueController"),
	LeagueInviteController: Symbol("LeagueInviteController"),
	LeagueSettingsController: Symbol("LeagueSettingsController"),
	LeagueAboutController: Symbol("LeagueAboutController"),
	LeagueInviteFormController: Symbol("LeagueInviteFormController"),
	ModalsStore: Symbol("ModalsStore"),
	ModalErrorController: Symbol("ModalErrorController"),
	ModalConfirmController: Symbol("ModalConfirmController"),
	LocalizationController: Symbol("LocalizationController"),
	HeaderController: Symbol("HeaderController"),
	MyPicksController: Symbol("MyPicksController"),
	GameplayStore: Symbol("MyPicksController"),
	GameplayApiProvider: Symbol("GameplayApiProvider"),
	ContestBannerController: Symbol("ContestBannerController"),
	ContestQuestionCardController: Symbol("ContestQuestionCardController"),
	RangeSliderController: Symbol("RangeSliderController"),
	ContestController: Symbol("ContestController"),
	ContestRootController: Symbol("ContestRootController"),
	BackdoorController: Symbol("BackdoorController"),
	StandingsController: Symbol("StandingsController"),
	StandingsApiProvider: Symbol("StandingsApiProvider"),
	StandingsStore: Symbol("StandingsStore"),
	ContestShareController: Symbol("ContestShareController"),
	ModalShareController: Symbol("ModalShareController"),
	ModalUserRegistrationController: Symbol("ModalUserRegistrationController"),
};
