import {SITE_URL, FACEBOOK_ID} from "data/constants";
import {ShareType, SocialNetwork} from "data/enums";
import {set, identity} from "lodash";
import {ConnextraType, createConnextraScriptTag} from "data/utils/connextra";

interface IContestShare {
	socialNetwork: SocialNetwork;
	type: ShareType.Contest;
	message?: string;
	locale: string | null;
	contestId?: number;
	isResult: boolean;
	userId: number;
}

type IShare = IContestShare;

export abstract class ShareUtility {
	private static callbacks = {
		[SocialNetwork.Twitter]: (data: IShare) => ShareUtility.shareTW(data),
		[SocialNetwork.Facebook]: (data: IShare) => ShareUtility.shareFB(data),
		[SocialNetwork.Mobile]: (data: IShare) => ShareUtility.shareMobile(data),
	} as const;

	public static share(data: IShare): boolean | void | Window | null {
		createConnextraScriptTag(ConnextraType.SHARE);

		const callback = ShareUtility.callbacks[data.socialNetwork];

		if (!callback || typeof callback !== "function") {
			throw new Error("Invalid share type");
		}

		return callback(data);
	}

	private static shareTW({message = "", ...params}: IShare): WindowProxy | null {
		return window.open(
			"https://twitter.com/share?url=" +
				encodeURIComponent(ShareUtility.getLinkForShare(params)) +
				"&text=" +
				encodeURIComponent(message),
			"twitter-share-dialog",
			"width=626,height=436"
		);
	}

	private static shareFB(data: IShare): void {
		const link = ShareUtility.getLinkForShare(data);

		if (!FACEBOOK_ID) {
			const winWidth = 626;
			const winHeight = 436;
			const winTop = window.screen.height / 2 - winHeight / 2;
			const winLeft = window.screen.width / 2 - winWidth / 2;

			window.open(
				`https://www.facebook.com/sharer/sharer.php?display=popup&u=${link}`,
				"_blank",
				`top=${winTop},left=${winLeft},width=${winWidth}, height=${winHeight},toolbar=0,status=0,scrollbars=no,resizable=1`
			);
			return;
		}

		window?.FB?.ui(
			{
				method: "share",
				display: "popup",
				href: link,
			},
			identity
		);
	}

	private static shareMobile({message = "", ...params}: IShare) {
		try {
			void navigator.share({
				url: ShareUtility.getLinkForShare(params),
				text: message,
			});
		} catch (err) {
			// trackSentryErrors(err, {}, "shareMobile");
			const error = err as Error;
			throw new Error(error.message);
		}
		return true;
	}

	private static getLinkForShare(data: IShare): string {
		const params = {
			time: this.getTimestamp(),
			userId: data.userId,
			locale: data.locale,
		};
		console.log(data);
		if (data.type === ShareType.Contest) {
			set(params, "contestId", data.contestId);
		}

		const sharePath = data.isResult ? "results" : "general";
		console.log(`${SITE_URL}share/${"en"}/${sharePath}/${ShareUtility.getEncodedJson(params)}`);

		return `${SITE_URL}share/${"en"}/${sharePath}/${ShareUtility.getEncodedJson(params)}`;
	}

	private static getTimestamp(): string {
		return Date.now().toString().substring(9);
	}

	private static getEncodedJson(object: Partial<IShare> & {time: string}): string {
		return window.btoa(JSON.stringify(object));
	}
}
