import React, {Fragment, useEffect} from "react";
import {useViewController} from "data/services/locator/locator_hook.service";
import {IContestBannerController} from "views/components/contest/contest_banner/contest_banner.contoller";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/exist/exist.component";
import {
	ContestCountdown,
	ContestCountdownWrapper,
} from "views/components/contest/contest_countdown/contest_countdown.component";
import {observer} from "mobx-react";
import {IContest} from "data/types/contests";
import dayjs from "dayjs";

interface IContestDateProps {
	contest: IContest;
	color?: string;
}

export const ContestDate: React.FC<IContestDateProps> = observer(({contest, color}) => {
	const controller = useViewController<IContestBannerController>(
		Bindings.ContestBannerController,
		{contest}
	);

	const {id, dateStart, dateEnd} = contest;

	const {i18n, setContestComplete, updateContest, isOpen, isComingSoon, isComplete} = controller;

	const displayEndDate = dayjs(dateEnd).format("dddd MMMM D, hh:mm a Z");

	useEffect(() => {
		updateContest(contest);
	}, [updateContest, contest]);

	return (
		<Fragment>
			<Exist when={isComingSoon}>
				<ContestCountdownWrapper color={color}>
					{i18n.t("contest.date.make_picks", "Make picks")}{" "}
					{dayjs(dateStart).format("dddd MMMM D, hh:mm a Z")}
				</ContestCountdownWrapper>
			</Exist>

			<Exist when={isOpen}>
				<ContestCountdown
					contestID={id}
					date={dateEnd}
					onComplete={setContestComplete}
					color={color}>
					{i18n.t("contest.date.locks_in", "Locks in")}{" "}
				</ContestCountdown>
			</Exist>

			<Exist when={isComplete}>
				<ContestCountdownWrapper color={color}>{displayEndDate}</ContestCountdownWrapper>
			</Exist>
		</Fragment>
	);
});
