import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ModalType, RequestState} from "data/enums";
import type {IModalsStore, IModalPayload} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUserStore} from "data/stores/user/user.store";
import {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import Cookies from "js-cookie";
import type {ChangeEvent} from "react";
import type {IGameplayStore} from "data/stores/gameplay/gameplay.store";

export interface IModalUserRegistrationController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get modalContent(): IModalPayload | null;
	get isNotificationsEnabled(): boolean;
	get isSelectOptIn(): boolean;
	get isSelectTerms(): boolean;
	get lockSubmit(): boolean;
	get displayName(): string;

	close: () => void;
	onChangeIsNotification: () => void;
	onChangeOpt: () => void;
	onChangeTerms: () => void;
	onSubmit: () => void;
	changeDisplayName: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

@injectable()
export class ModalUserRegistrationController implements IModalUserRegistrationController {
	@observable private _requestState: RequestState = RequestState.IDLE;
	@observable private _isNotificationsEnabled: boolean = true;
	@observable private _isSelectOptIn: boolean = false;
	@observable private _isSelectTerms: boolean = false;
	@observable private _displayName = "";
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.GameplayStore) private readonly _gameplayStore: IGameplayStore
	) {
		makeAutoObservable(this);
	}

	@action _onCatchError(err: unknown) {
		const error = err as AxiosError;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: error.message,
		});
	}

	get session() {
		return this.modalContent?.session;
	}

	get isNotificationsEnabled() {
		return this._isNotificationsEnabled;
	}

	get isSelectOptIn() {
		return this._isSelectOptIn;
	}

	get isSelectTerms() {
		return this._isSelectTerms;
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.USER_REGISTRATION;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	get lockSubmit() {
		return (
			this._requestState === RequestState.PENDING ||
			!this._isSelectTerms ||
			this._displayName === ""
		);
	}

	get displayName() {
		return this._displayName;
	}

	close = () => {
		this._modalsStore.hideModal();
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: error.message,
		});
	};

	@action onChangeIsNotification = () => {
		this._isNotificationsEnabled = !this._isNotificationsEnabled;
	};

	@action onChangeOpt = () => {
		this._isSelectOptIn = !this._isSelectOptIn;
	};

	@action onChangeTerms = () => {
		this._isSelectTerms = !this._isSelectTerms;
	};

	@action changeDisplayName = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		this._displayName = e.target.value;
	};

	@action onSubmit = () => {
		if (this.session) {
			this._requestState = RequestState.PENDING;
			this._userStore
				.register({
					session: this.session,
					terms: this._isSelectTerms,
					marketingOptIn: this._isSelectOptIn,
					isNotificationsEnabled: this._isNotificationsEnabled,
					displayName: this.displayName,
				})
				.then(() => {
					this._requestState = RequestState.SUCCESS;
					const savedAnswers = JSON.parse(Cookies.get("answers") || "false") as
						| {
								answers: {
									questionId: number;
									optionId: number | null;
								}[];
								contest: number;
						  }
						| false;

					if (savedAnswers) {
						const queries = {
							contest: savedAnswers.contest,
							answers: savedAnswers.answers,
						};

						this._gameplayStore
							.submitAnswers(queries)
							.then(() => {
								window.location.href = `/contest/${String(
									savedAnswers.contest
								)}/result`;
							})
							.catch(this.onError)
							.finally(() => {
								Cookies.remove("answers");
							});
					} else {
						window.location.href = `/`;
					}
					this.close();
				})
				.catch(this.onError)
				.finally(() => {
					window.history.replaceState(null, "", window.location.pathname);
				});
		}
	};
}
