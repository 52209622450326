import {ModalType, RequestState} from "data/enums";
import type {IGameplayStore} from "data/stores/gameplay/gameplay.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {isEmpty, isEqual} from "lodash";
import {action, computed, makeAutoObservable, observable, runInAction} from "mobx";
import React from "react";
import {Bindings} from "data/constants/bindings";
import {IContest} from "data/types/contests";
import {AxiosError} from "axios";
import type {IUserStore} from "data/stores/user/user.store";

export const TABS = {
	ALL: "All",
	CURRENT: "Current",
	PAST: "Past",
} as const;

type ITabValue = (typeof TABS)[keyof typeof TABS];

export interface IMyPicksController extends ViewController {
	get i18n(): ILocalizationStore;
	get contestsRequestState(): RequestState;
	get pastContests(): IContest[];
	get currentContests(): IContest[];
	get selectedTab(): ITabValue;
	get isLoading(): boolean;
	handleChangeTab: (event: React.MouseEvent<HTMLElement>, newTab: ITabValue | null) => void;
}

@injectable()
export class MyPicksController implements IMyPicksController {
	@observable private _selectedTab: ITabValue = TABS.ALL;
	@observable private _contestsRequestState: RequestState = RequestState.IDLE;

	get contestsRequestState() {
		return this._contestsRequestState;
	}

	get isLoading() {
		const isRequestPending = isEqual(this._contestsRequestState, RequestState.PENDING);
		const hasNoContests = isEmpty(this._gameplayStore.allContests);

		return isRequestPending && hasNoContests;
	}

	@computed get pastContests() {
		const {pastContests} = this._gameplayStore;
		return pastContests;
	}

	@computed get currentContests() {
		const {currentContests} = this._gameplayStore;
		return currentContests;
	}

	get selectedTab() {
		return this._selectedTab;
	}

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.GameplayStore) private _gameplayStore: IGameplayStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@action handleChangeTab = (_: React.MouseEvent<HTMLElement>, newTab: ITabValue | null) => {
		if (!newTab) return;
		this._selectedTab = newTab;
	};

	dispose(): void {
		return;
	}

	@action init() {
		this._contestsRequestState = RequestState.PENDING;
		void this._gameplayStore
			.requestContestsSafety()
			.then(() => runInAction(() => (this._contestsRequestState = RequestState.SUCCESS)))
			.catch((err) => {
				// trackSentryErrors(err, {}, "dashboard - request contests");

				runInAction(() => {
					this._contestsRequestState = RequestState.ERROR;
					const error = err as AxiosError;
					this._modalsStore.showModal(ModalType.ERROR, {
						message: error.message,
					});
				});
			});
		if (this._userStore.user!) {
			void this._gameplayStore
				.requestAnsweredContests()
				.then(() => runInAction(() => (this._contestsRequestState = RequestState.SUCCESS)))
				.catch((err) => {
					// trackSentryErrors(err, {}, "dashboard - request contests");

					runInAction(() => {
						this._contestsRequestState = RequestState.ERROR;
						const error = err as AxiosError;
						this._modalsStore.showModal(ModalType.ERROR, {
							message: error.message,
						});
					});
				});
		}
	}
}
