export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum ModalType {
	ERROR,
	CONFIRM,
	SHARE,
	USER_REGISTRATION,
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
	League = "league",
	Contest = "Contest",
}

export enum Locale {
	EN_US = "en-us",
}

export enum Language {
	EN = "en",
}

export enum LeaguePrivacy {
	PUBLIC = "public",
	PRIVATE = "private",
}

export enum LeagueType {
	REGULAR = "regular",
	OVERALL = "overall",
}

export enum LeagueStatus {
	COMPLETE = "complete",
	PLAYING = "playing",
	SCHEDULED = "scheduled",
}

export enum ContestStatus {
	ACTIVE = "active",
	COMPLETED = "completed",
	SCHEDULED = "scheduled",
	LOCKED = "locked",
}

export enum QuestionHeaderStatus {
	CORRECT = "correct",
	INCORRECT = "incorrect",
	DISABLED = "disabled",
}

export enum HelpPagesEnum {
	GUIDELINES = "guidelines",
	TERMS = "terms",
	PRIVACY = "privacy",
	HOW_TO_PLAY = "how_to_play",
}
