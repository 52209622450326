import {retryFailLoad} from "data/utils";
import React, {Fragment, lazy} from "react";
import {Route} from "react-router-dom";

const ComingSoon = lazy(() => import("views/pages/coming_soon/coming_soon"));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));

export const SecretGateRoutes = (
	<Fragment>
		<Route index element={<ComingSoon />} />
		<Route path="*" element={<NotFound />} />
	</Fragment>
);

export default SecretGateRoutes;
