import {ViewController} from "data/types/structure";
import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";

export interface IContestRootController extends ViewController {
	get user(): IUser | undefined;
}

@injectable()
export class ContestRootController implements IContestRootController {
	constructor(@inject(Bindings.UserStore) private _userStore: IUserStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init() {
		return;
	}

	get user() {
		return this._userStore?.user;
	}
}
