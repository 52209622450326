import React, {useCallback, useMemo} from "react";
import {observer} from "mobx-react";
import {Button, Modal, Stack, useMediaQuery} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ReactComponent as IconInfo} from "assets/img/icons/Info.svg";
import {ReactComponent as IconClose} from "assets/img/icons/close.svg";
import {Exist} from "views/components/exist/exist.component";
import type {IModalShareController} from "views/components/modals/modal_share/modal_share.controller";
import {NavLink, useParams} from "react-router-dom";
import {IContestRouteParams} from "data/types/global";
import {ButtonPure, ButtonSecondary} from "views/components/buttons/buttons.component";
import {ShareUtility} from "data/utils";
import {ShareType, SocialNetwork} from "data/enums";
import {ReactComponent as DownloadIcon} from "assets/img/icons/downloadIcon.svg";
import {ReactComponent as FacebookIcon} from "assets/img/icons/facebook.svg";
import {ReactComponent as TwitterIcon} from "assets/img/icons/twitter.svg";

const MOBILE_BREAKPOINT = "640px";

const ModalContent = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	outline: none;
	max-width: 600px;
	width: 100%;
	background: #fff;
	padding: 20px;
	border-radius: 4px;
	text-align: center;

	@media screen and (max-width: 640px) {
		max-width: unset;
		left: 24px;
		right: 24px;
		width: auto;
		transform: translateY(-50%);
	}
`;

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	svg {
		fill: #4dc1ef;
	}
`;

const Icon = styled.div`
	width: 80px;

	svg {
		width: 100%;
		height: auto;
		fill: #c20000;
	}
	@media (max-width: 960px) {
		margin-top: -40px;
	}
`;

const Title = styled.h2`
	font-size: 20px;
	font-style: normal;
	font-weight: 900;
	line-height: 20px;
	text-transform: uppercase;
`;

const Description = styled.div`
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	margin: 10px 0;
`;

const Image = styled.img`
	margin: 10px;
	width: 460px;

	@media (max-width: 960px) {
		width: 295px;
	}
`;

const WrapperForButtons = styled(Stack)`
	width: 460px;
	@media (max-width: 960px) {
		width: 295px;
		flex-direction: column;
		gap: 14px;
	}
	button {
		padding: 14px 16px;
		max-width: 300px;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px;
		color: #0c2b44;
		background: #4dc1ef;
		border-radius: 0;
	}
`;

export const ModalShare: React.FC = observer(() => {
	const contestId = Number(useParams<IContestRouteParams>().id);
	const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT})`);
	const isSupportMobileShare = "share" in navigator && isMobile;
	const {isOpen, i18n, close, user, shareImageUrl, message, endDate, isShowLocalText, isResult} =
		useViewController<IModalShareController>(Bindings.ModalShareController, {
			contestId,
		});

	const shareProps = useMemo(
		() => ({
			message,
			type: ShareType.Contest as const,
			contestId,
			locale: i18n.locale,
			isResult,
			userId: user?.id || 0,
		}),
		[contestId, i18n.locale, isResult, user, message]
	);

	const shareTW = useCallback(
		() =>
			ShareUtility.share({
				...shareProps,
				socialNetwork: SocialNetwork.Twitter,
			}),
		[shareProps]
	);

	const shareFB = useCallback(
		() =>
			ShareUtility.share({
				...shareProps,
				socialNetwork: SocialNetwork.Facebook,
			}),
		[shareProps]
	);

	const shareMobile = useCallback(
		() =>
			ShareUtility.share({
				...shareProps,
				socialNetwork: SocialNetwork.Mobile,
			}),
		[shareProps]
	);

	const description = isShowLocalText
		? i18n.t(
				"contest.result.description_locked",
				"This contest is now locked! Check back in at the end of the tournament to see your results!"
		  )
		: i18n.t(
				"contest.result.description",
				"You have {{X}} time to edit your picks. Check back soon to see your results.",
				{X: `<strong>${endDate}</strong>`}
		  );

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<CloseBlock>
					<Button sx={{minWidth: 0}} onClick={close}>
						<IconClose />
					</Button>
				</CloseBlock>
				<Icon>
					<IconInfo />
				</Icon>
				<Title>{i18n.t("modal.share.title", "HURRAY, YOUR PICKS ARE IN!")}</Title>
				<Exist when={description !== " "}>
					<Description dangerouslySetInnerHTML={{__html: description}} />
				</Exist>

				<Image src={shareImageUrl} alt={""} />

				<WrapperForButtons direction="row" gap={3}>
					<ButtonSecondary
						fullWidth
						variant="contained"
						component={NavLink}
						to={`/`}
						onClick={close}>
						{i18n.t("modal.share.home", "Home")}
					</ButtonSecondary>
					<ButtonSecondary
						fullWidth
						variant="contained"
						component={NavLink}
						to={`/contest/${contestId}`}
						onClick={close}>
						{i18n.t("modal.share.view_pics", "View Picks")}
					</ButtonSecondary>
					<Exist when={isSupportMobileShare}>
						<ButtonSecondary fullWidth variant="contained" onClick={shareMobile}>
							{i18n.t("modal.share.share_text", "Share")}
						</ButtonSecondary>
					</Exist>
				</WrapperForButtons>

				<Exist when={!isSupportMobileShare}>
					<Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
						<ButtonPure onClick={shareTW}>
							<TwitterIcon style={{color: "#000"}} />
						</ButtonPure>
						<ButtonPure onClick={shareFB}>
							<FacebookIcon />
						</ButtonPure>
						<Exist when={isResult}>
							<a href={shareImageUrl} download>
								<DownloadIcon />
							</a>
						</Exist>
					</Stack>
				</Exist>
			</ModalContent>
		</Modal>
	);
});
