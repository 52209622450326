import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import Cookies from "js-cookie";

export interface ISessionController extends ViewController {
	get isSessionChecked(): boolean;
}

@injectable()
export class SessionController implements ISessionController {
	@observable _isSessionChecked = false;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isSessionChecked(): boolean {
		return this._isSessionChecked;
	}

	get session() {
		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search);

		return params.get("session");
	}

	private clearSession() {
		window.history.replaceState(null, "", window.location.pathname);
	}

	@action init() {
		if (this.session) {
			console.log(this.session);
			void this._userStore
				.login(this.session)
				.then(() => {
					this.clearSession();
					const savedAnswers = JSON.parse(Cookies.get("answers") || "false") as
						| {
								answers: {
									questionId: number;
									optionId: number | null;
								}[];
								contest: number;
						  }
						| false;

					if (savedAnswers) {
						window.location.href = `/contest/${String(savedAnswers.contest)}/result`;
					} else {
						window.location.href = `/`;
					}
				})
				.catch(() => {
					this._modalsStore.showModal(ModalType.USER_REGISTRATION, {
						session: this.session,
					});
				})
				.finally(() => {
					runInAction(() => {
						this._isSessionChecked = true;
					});
				});
		} else {
			void this._userStore
				.requestUser()
				.catch(() => {
					// Do nothing as the error is expected when a user isn't authorized
				})
				.then(() =>
					runInAction(() => {
						this._isSessionChecked = true;
					})
				);
		}
	}
}
