import React, {Fragment} from "react";
import {useViewController} from "data/services/locator/locator_hook.service";
import {useParams} from "react-router-dom";
import {IContestRouteParams} from "data/types/global";
import {Bindings} from "data/constants/bindings";
import type {IContestShareController} from "./contest_share.controller";

interface IProps {
	component: React.ReactElement;
	message?: string;
	isResult?: boolean;
}

export const ContestShare: React.FC<IProps> = ({component, message = "", isResult = false}) => {
	const contestId = Number(useParams<IContestRouteParams>().id);
	const {openShareModal} = useViewController<IContestShareController>(
		Bindings.ContestShareController,
		{contestId, isResult, message}
	);

	const buttonComponent = React.cloneElement(component, {
		onClick: openShareModal,
	});

	return <Fragment>{buttonComponent}</Fragment>;
};
