import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type IUserStore} from "data/stores/user/user.store";
import React from "react";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import {RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {ILoginPayload, IBackdoorPayload} from "data/providers/api/auth.api.provider";
import type {IApiResponse} from "data/services/http";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {extractErrorMessage} from "data/utils";
import Cookies from "js-cookie";

interface IBackdoorForm extends HTMLFormElement {
	email: HTMLInputElement;
}

export interface IBackdoorController extends ViewController {
	readonly i18n: ILocalizationStore;

	get error(): Record<string, string> | null;
	get isFormDisabled(): boolean;

	handleFormSubmit: (event: React.SyntheticEvent<IBackdoorForm>) => void;
	login: (params: ILoginPayload) => Promise<void>;
	handleFormOnChange: () => void;
}

@injectable()
export class BackdoorController implements IBackdoorController {
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	init(param: void) {
		if (process.env.REACT_APP_ENVIRONMENT === "production") {
			window.location.href = "/";
		}
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled() {
		return this._requestState === RequestState.PENDING;
	}

	@action private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action handleFormOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._requestState = RequestState.IDLE;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this.reportError(extractErrorMessage(error));
	};

	@action login(payload: IBackdoorPayload) {
		this._requestState = RequestState.PENDING;
		return this._userStore
			.backdoor(payload)
			.then(() => {
				const savedAnswers = JSON.parse(Cookies.get("answers") || "false") as
					| {
							answers: {
								questionId: number;
								optionId: number | null;
							}[];
							contest: number;
					  }
					| false;

				if (savedAnswers) {
					window.location.href = `/contest/${String(savedAnswers.contest)}/result`;
				} else {
					window.location.href = `/`;
				}
			})
			.catch(this.onError);
	}

	@action handleFormSubmit = (event: React.SyntheticEvent<IBackdoorForm>) => {
		event.preventDefault();
		const {email} = event.currentTarget;

		if (!email.checkValidity()) {
			return this.reportError(
				this.i18n.t("login.email.error", "Please provide a valid email address"),
				"email"
			);
		}

		void this.login({
			email: email.value,
		});
	};
}
