import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";

export interface ILoginPayload {
	email: string;
	password: string;
}

export interface IRegistrationPayload {
	session: string;
	terms: boolean;
	isNotificationsEnabled: boolean;
}

export interface IBackdoorPayload {
	email: string;
}

type TLoginResponse = IApiResponse<{user: IUser}>;
type TGetSSOUrlResponse = IApiResponse<{url: string}>;

export interface IAuthApiProvider {
	getSSOUrl: () => Promise<AxiosResponse<TGetSSOUrlResponse>>;
	loginSSO: (session: string) => Promise<AxiosResponse<TLoginResponse>>;
	registerSSO: (session: IRegistrationPayload) => Promise<AxiosResponse<TLoginResponse>>;

	login: (params: ILoginPayload) => Promise<AxiosResponse<TLoginResponse>>;
	backdoor: (params: IBackdoorPayload) => Promise<AxiosResponse<TLoginResponse>>;
	logout: () => Promise<AxiosResponse>;
}

@injectable()
export class AuthApiProvider implements IAuthApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	getSSOUrl = () => this._http.get<TGetSSOUrlResponse>("auth/pickleball-auth-url");

	loginSSO = (session: string) =>
		this._http.post<TLoginResponse>(`auth/pickleball-login/${session}`);

	registerSSO = ({session, terms, isNotificationsEnabled}: IRegistrationPayload) =>
		this._http.post<TLoginResponse>(`auth/pickleball-register/${session}`, {
			terms,
			isNotificationsEnabled,
		});

	login = (params: ILoginPayload) => this._http.post<TLoginResponse>("auth/login", params);
	backdoor = (params: IBackdoorPayload) =>
		this._http.post<TLoginResponse>("auth/backdoor", params);
	logout = () => this._http.post("auth/logout");
}
