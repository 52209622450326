import styled from "@emotion/styled";
import {css} from "@mui/material";
import headerBackground from "assets/img/masthead.jpg";
import headerBackgroundMobile from "assets/img/masthead_mobile.jpg";

export const Container = styled.div`
	max-width: 1400px;
	width: 100%;
	padding: 0 32px;
	position: relative;
	z-index: 5;
	margin: 0 auto;
	box-sizing: border-box;

	@media (max-width: 870px) {
		padding-left: 20px;
		padding-right: 20px;
	}
`;

interface IPageHeader {
	size?: "small";
}

export const PageHeader = styled.section<IPageHeader>`
	width: 100%;
	color: #fff;
	min-height: 230px;
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	padding-top: 43px;
	padding-bottom: 43px;
	background: url(${headerBackground}) no-repeat;
	background-size: cover;
	background-position: 75% 22%;

	h1 {
		font-size: 30px;
		text-transform: uppercase;
	}

	@media (max-width: 870px) {
		min-height: 90px;
		padding-top: 20px;
		padding-bottom: 20px;
		background: url(${headerBackgroundMobile}) left top no-repeat;
		background-size: cover;
		margin-bottom: 0;

		h1 {
			font-size: 24px;
		}
	}

	${(props) =>
		props.size === "small"
			? css`
					min-height: 64px;
					margin-bottom: 0;
					padding: 20px;
					font-size: 24px;
					font-weight: 700;

					@media (max-width: 870px) {
						margin-bottom: 0;
						min-height: 64px;
					}
			  `
			: ""};
`;

export const PageTitle = styled.h2`
	font-size: 28px;
	line-height: 1.2;
	letter-spacing: 0.2px;
	font-family: var(--fontFamilySecond);
	text-transform: uppercase;
`;
