import {action, computed, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {filter, find} from "lodash";
import {ContestUtils} from "data/utils/contest_utils";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {
	IContest,
	IQuestionWithAnswers,
	IAnsweredContest,
	IQuestionIntro,
} from "data/types/contests";
import {
	type IGameplayApiProvider,
	ISaveRequests,
	IGetRequests,
	IPayloadSubmitAnswers,
	type ISharingImage,
} from "data/providers/api/gameplay.api.provider";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

export interface IGameplayStore {
	get allContests(): IContest[];
	get scheduledContests(): IContest[];
	get actualContests(): IContest[];
	get activeContests(): IContest[];
	get pastContests(): IContest[];
	get currentContests(): IContest[];
	get upcomingContests(): IContest[];
	get answeredContests(): IAnsweredContest[];

	getQuestionsIntrosByContestID(contestID: number): (IQuestionIntro | null)[];
	getQuestionsByContestID(contestID: number): IQuestionWithAnswers[];

	requestContests(): Promise<IContest[]>;

	requestContestsSafety(): Promise<IContest[]>;
	setContestComplete(contestID: number): void;

	submitAnswers(params: IPayloadSubmitAnswers): Promise<ISaveRequests["response"]>;

	requestAnsweredContests(): Promise<IAnsweredContest[]>;
	requestData(params: IGetRequests["variables"]): Promise<IGetRequests["response"]["success"]>;
	requestShareImg(params: ISharingImage): Promise<string>;
	setNotAuthQuestionsByContestID(contestID: number): void;
}

@injectable()
export class GameplayStore implements IGameplayStore {
	@observable _contests: IContest[] = [];
	@observable _questionsByContestID: Record<string, IQuestionWithAnswers[]> = {};
	@observable _questionsIntrosByContestID: Record<string, (IQuestionIntro | null)[]> = {};
	@observable _answeredContests: IAnsweredContest[] = [];

	get allContests() {
		return this._contests;
	}
	@computed
	get answeredContests() {
		return this._answeredContests;
	}

	@computed get scheduledContests() {
		return filter(this._contests, ContestUtils.isScheduled);
	}

	@computed get actualContests() {
		return filter(this._contests, ContestUtils.isActual);
	}

	@computed get activeContests() {
		return filter(this._contests, ContestUtils.isActive);
	}

	@computed get pastContests() {
		return filter(this._contests, ContestUtils.isPast);
	}

	@computed get currentContests() {
		return filter(this._contests, ContestUtils.isCurrent);
	}

	@computed get upcomingContests() {
		return filter(this._contests, ContestUtils.isUpcoming);
	}

	@computed getQuestionsByContestID(contestID: number) {
		return this._questionsByContestID[contestID] ?? [];
	}

	@computed getQuestionsIntrosByContestID(contestID: number) {
		return this._questionsIntrosByContestID[contestID];
	}

	constructor(
		@inject(Bindings.GameplayApiProvider) private _gameplayApi: IGameplayApiProvider,
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.LocalizationStore) private readonly _localizationStore: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	@action setContestComplete(contestID: number): void {
		const contest = find(this.allContests, {id: contestID})!;
		contest.status = ContestUtils.status.COMPLETED;
	}

	@action requestContests() {
		const locale = (this._localizationStore.lang || "en").toLowerCase();
		return this._jsonProvider.contests(locale).then((resp) => {
			const {contests} = resp.data;
			runInAction(() => (this._contests = contests));

			return contests;
		});
	}

	@action
	async requestAnsweredContests() {
		const response = await this._gameplayApi.getAnsweredContests();
		runInAction(() => {
			this._answeredContests = response.data.success.contests.filter(
				({answers}: IAnsweredContest) => +answers.length > 0
			);
		});
		return response.data.success.contests;
	}

	@action async requestContestsSafety() {
		if (this._contests.length) {
			return Promise.resolve(this._contests);
		}

		return this.requestContests();
	}

	async submitAnswers(params: IPayloadSubmitAnswers): Promise<ISaveRequests["response"]> {
		const response = await this._gameplayApi.submitAnswers(params);
		return response.data;
	}

	@action
	async requestData(params: IGetRequests["variables"]) {
		const response = await this._gameplayApi.getAnswers(params);
		runInAction(() => {
			const contestQuestions =
				this.allContests.find((contest) => contest.id === params.contestId)?.questions ||
				[];

			this._questionsByContestID[params.contestId] = contestQuestions.map((question) => ({
				...question,
				answer:
					response.data.success.answers?.find(
						(answer) => answer.questionId === question.id
					) ?? null,
			}));
		});
		return response.data.success;
	}

	@action
	async requestShareImg(params: ISharingImage) {
		const response = await this._gameplayApi.getShareImg(params);
		return response.data.success.image;
	}

	@action
	setNotAuthQuestionsByContestID(contestId: number) {
		runInAction(() => {
			const contestQuestions =
				this.allContests.find((contest) => contest.id === contestId)?.questions || [];
			const questionsWithIntro = contestQuestions.filter((question) => !!question.intro);

			this._questionsIntrosByContestID[contestId] =
				questionsWithIntro.map((q) => q.intro).filter((intro) => intro !== null) || [];

			this._questionsByContestID[contestId] = contestQuestions.map((question) => ({
				...question,
				answer: null,
			}));
		});
	}
}
