import styled from "@emotion/styled";
import {Button} from "@mui/material";

export const PureButton = styled.button`
	border: 0;
	padding: 0;
	margin: 0;
	background: none;
	cursor: pointer;
`;

const ButtonPrimaryBase = styled(Button)``;

ButtonPrimaryBase.defaultProps = {
	variant: "contained",
	style: {
		transition: "opacity 1s",
		borderRadius: "0",
		background: "#4DC1EF",
		color: "#092033",
		fontWeight: "700",
		minHeight: "46px",
		textTransform: "none",
	},
};

const ButtonSecondaryBase = styled(ButtonPrimaryBase)``;

ButtonSecondaryBase.defaultProps = {
	color: "info",
	style: {
		background: "#ffffff",
		color: "#092033",
		border: "1px solid #092033",
		borderRadius: "0",
		fontWeight: "700",
		minHeight: "46px",
		textTransform: "none",
	},
};

/**
 * Type casting is required because of MUI issue
 * https://github.com/mui/material-ui/issues/13921#issuecomment-484133463
 */
export const ButtonPrimary = ButtonPrimaryBase as typeof Button;

export const ButtonSecondary = ButtonSecondaryBase as typeof Button;

export const ButtonPure = styled.button`
	padding: 0;
	margin: 0;
	outline: none;
	background: none;
	border: none;
	cursor: pointer;
`;
