import {inject, injectable} from "inversify";
import {makeAutoObservable, action, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IStandingsApiProvider} from "data/providers/api/standings.api.provider";
import type {IRankUser, IRankingVariables, IRankings} from "data/types/standings";

export interface IStandingsStore {
	getLadder(params: IRankingVariables): Promise<void>;
	clear(): void;
	get ladder(): IRankings;
	get userRank(): IRankUser | null;
}

@injectable()
export class StandingsStore implements IStandingsStore {
	@observable _ladder: IRankings = {
		nextPage: false,
		rankings: [],
		user: null,
	};

	constructor(
		@inject(Bindings.StandingsApiProvider) private _standingsApiProvider: IStandingsApiProvider
	) {
		makeAutoObservable(this);
	}

	get ladder(): IRankings {
		return this._ladder;
	}

	get userRank(): IRankUser | null {
		return this._ladder.user;
	}

	@action
	async getLadder(params: IRankingVariables) {
		const {data} = await this._standingsApiProvider.fetch(params);
		const ladder = data.success?.rankings || [];
		const previousRanks = this._ladder.rankings || [];

		runInAction(() => {
			if (data.success) {
				this._ladder = {
					...data.success,
					rankings: [...previousRanks, ...ladder],
				};
			}
		});
	}

	@action
	clear() {
		this._ladder = {
			nextPage: false,
			rankings: [],
			user: null,
		};
	}
}
