import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, computed, makeAutoObservable, observable, reaction, when, runInAction} from "mobx";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ContestStatus, ModalType, RequestState} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IGameplayStore} from "data/stores/gameplay/gameplay.store";
import {find, isEmpty, isEqual, size} from "lodash";
import {type IStepper, Stepper} from "data/utils/stepper/stepper.utility";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {IContest, IQuestionIntro, IQuestionWithAnswers} from "data/types/contests";
import {QuestionUtils} from "data/utils/question_utils";
import Cookies from "js-cookie";
import {AxiosError} from "axios";
import {ContestUtils} from "data/utils/contest_utils";
import moment from "moment/moment";
import {getByIndex, copyToClipboard} from "data/utils";
import {ConnextraType, createConnextraScriptTag} from "data/utils/connextra";
import {SITE_URL} from "data/constants";

interface IControllerProps {
	contestID: number;
}

export interface IContestController extends ViewController<IControllerProps> {
	get i18n(): ILocalizationStore;

	get requestState(): RequestState;

	get allContests(): IContest[];

	get activeContests(): IContest[];

	get currentContest(): IContest | null;

	get contestQuestions(): IQuestionWithAnswers[] | undefined;

	get contestQuestionsWithIntros(): (IQuestionWithAnswers | IQuestionIntro)[];

	get isLoading(): boolean;

	get isQuestionsLoading(): boolean;

	get isAuthorized(): boolean;

	get buttonCopy(): string;

	get user(): IUser;

	get totalPoints(): number;

	get endDate(): string;

	get questionHasChanged(): boolean;

	get isHaveAnswerForCurrentStep(): boolean;

	get isShowLocalText(): boolean;

	get isUrlCopied(): boolean;

	updateContestID: (contestID: number) => void;
	setContestComplete: (contestID: number) => void;
	stepper: IStepper;
	onCompleteClick: () => void;
	handleQuestionAnswer: (questionId: number, optionId: number, range?: number | null) => void;
	copyContestUrl: () => void;
}

@injectable()
export class ContestController implements IContestController {
	@observable private _contestID!: number;
	@observable private _requestState: RequestState = RequestState.IDLE;
	@observable public stepper = new Stepper();
	private _questionsReactionDisposer!: ReturnType<typeof when>;
	@observable private _questionHasChanged = false;
	@observable private _isUrlCopied = false;

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}
	get buttonCopy() {
		if (this.currentContest?.status === "active" && this._userStore.isAuthorized) {
			return this.i18n.t("contest.result.edit_button", "Edit Picks");
		}
		return this.i18n.t("contest.result.view_button", "View Picks");
	}
	get requestState() {
		return this._requestState;
	}

	get allContests() {
		return this._gameplayStore.allContests;
	}

	get activeContests() {
		return this._gameplayStore.scheduledContests;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isQuestionsLoading() {
		return this.isLoading && isEmpty(this.contestQuestions);
	}

	get user() {
		return this._userStore.user!;
	}

	get totalPoints() {
		const rightAnswers = this.contestQuestions.filter(({answer}) => answer?.isCorrect);
		return ContestUtils.getTotalPoints(rightAnswers);
	}

	get isUrlCopied() {
		return this._isUrlCopied;
	}

	get endDate() {
		const startDate = moment();
		const timeEnd = moment(this.currentContest?.dateStart);
		const diff = timeEnd.diff(startDate);
		const diffDuration = moment.duration(diff);
		const checkForTime = (time: number) => (time >= 0 ? time : 0);

		return `${checkForTime(diffDuration.days())} days  ${checkForTime(
			diffDuration.hours()
		)} hours ${checkForTime(diffDuration.minutes())} minutes`;
	}

	get isHaveAnswerForCurrentStep() {
		const currentQuestion = getByIndex(
			this.contestQuestionsWithIntros,
			this.stepper.currentStep
		) as IQuestionWithAnswers;
		if (
			this.currentContest?.status === ContestStatus.COMPLETED ||
			this.currentContest?.status === ContestStatus.LOCKED
		) {
			return true;
		}

		return Boolean(currentQuestion?.answer);
	}

	get isShowLocalText() {
		if (!this.currentContest) {
			return false;
		}

		const startDate = moment();
		const timeEnd = moment(this.currentContest?.dateStart);
		const diff = timeEnd.diff(startDate);
		const diffDuration = moment.duration(diff);
		const checkForTime = (time: number) => (time >= 0 ? time : 0);

		return (
			ContestUtils.isLocked(this.currentContest) ||
			(checkForTime(diffDuration.days()) === 0 &&
				checkForTime(diffDuration.hours()) === 0 &&
				checkForTime(diffDuration.minutes()) === 0)
		);
	}

	@computed get contestQuestions(): IQuestionWithAnswers[] {
		return this._gameplayStore.getQuestionsByContestID(this._contestID);
	}

	@computed get contestQuestionsWithIntros(): (IQuestionIntro | IQuestionWithAnswers)[] {
		const questions = this.contestQuestions;
		const ar: (IQuestionIntro | IQuestionWithAnswers)[] = [];
		questions.forEach((question) => {
			if (question.intro) {
				ar.push(question.intro);
				ar.push(question);
			} else {
				ar.push(question);
			}
		});
		return ar;
	}

	@computed get contestQuestionsIntros(): (IQuestionIntro | null)[] {
		return this._gameplayStore.getQuestionsIntrosByContestID(this._contestID);
	}

	@computed get currentContest() {
		return find(this._gameplayStore.allContests, {id: this._contestID}) || null;
	}

	get questionHasChanged() {
		return this._questionHasChanged;
	}

	constructor(
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.GameplayStore) private readonly _gameplayStore: IGameplayStore,
		@inject(Bindings.UserStore) private readonly _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@action public handleQuestionAnswer = (questionID: number, optionId: number) => {
		const question = find(this.contestQuestions, {id: questionID});
		if (!question || !QuestionUtils.isScheduled(question)) {
			return;
		}

		this._questionHasChanged = true;

		question.answer = {
			isCorrect: null,
			questionId: questionID,
			optionId: optionId,
		};

		// If all questions have answers
		if (!this.contestQuestions.find((question) => question.answer === null)) {
			this._questionHasChanged = false;
		}
	};

	@action updateContestID = async (contestID: number) => {
		await this.requestData(contestID);
		runInAction(() => {
			this._contestID = contestID;
		});
	};

	@action setContestComplete = (contestID: number) => {
		this._gameplayStore.setContestComplete(contestID);
	};

	@action
	private async requestData(contestId: number) {
		this._requestState = RequestState.PENDING;

		try {
			if (isEmpty(this.allContests)) {
				await this._gameplayStore.requestContestsSafety();
			}
			if (contestId) {
				if (this.isAuthorized) {
					await this._gameplayStore.requestData({
						locale: this.i18n.locale,
						contestId: contestId,
					});
				} else {
					this._gameplayStore.setNotAuthQuestionsByContestID(contestId);
				}
			}
			this.onSuccess();
		} catch (e) {
			this.onError(e as AxiosError);
		}
	}

	@action onError = (error: AxiosError) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: error.message,
		});
	};

	@action onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action init({contestID}: IControllerProps) {
		this._contestID = contestID;
		this._questionsReactionDisposer = reaction(
			() => this.contestQuestionsWithIntros,
			(questions) => {
				this.stepper = new Stepper(size(questions));
			},
			{fireImmediately: true}
		);
		this.saveAnswersIfNeed();
	}

	dispose(): void {
		this._questionsReactionDisposer();
	}

	saveAnswersIfNeed = () => {
		const savedAnswers = JSON.parse(Cookies.get("answers") || "false") as
			| {
					answers: {
						questionId: number;
						optionId: number | null;
					}[];
					contest: number;
			  }
			| false;

		if (savedAnswers && this.isAuthorized) {
			const queries = {
				contest: savedAnswers.contest,
				answers: savedAnswers.answers,
			};

			this._gameplayStore
				.submitAnswers(queries)
				.catch(() => {
					window.location.href = `/`;
				})
				.finally(() => {
					Cookies.remove("answers");
				});
		}
	};

	@action
	goToSSO = () => {
		this._requestState = RequestState.PENDING;
		void this._userStore.goToSSO().catch(this.onError);
	};

	onCompleteClick = () => {
		this._questionHasChanged = false;

		if (
			this.currentContest?.status === ContestStatus.COMPLETED ||
			this.currentContest?.status === ContestStatus.LOCKED
		) {
			window.location.href = `/contest/${String(this.currentContest?.id)}/result`;
		} else {
			const answers: {
				questionId: number;
				optionId: number | null;
			}[] = this.contestQuestions.map((question) => {
				return {
					questionId: question.id,
					optionId: question.answer?.optionId || null,
				};
			});

			const queries = {
				contest: this._contestID,
				answers,
			};

			if (!this.isAuthorized) {
				Cookies.set("answers", JSON.stringify(queries));
				this.goToSSO();
				return;
			}

			createConnextraScriptTag(ConnextraType.PICK_CONFIRM, this.user);

			this._gameplayStore
				.submitAnswers(queries)
				.then(() => {
					window.location.href = `/contest/${String(this.currentContest?.id)}/result`;
				})
				.catch(this.onError);
		}
	};

	copyContestUrl = () => {
		copyToClipboard(`${SITE_URL}contest/${String(this.currentContest?.id)}`).finally(() => {
			this._isUrlCopied = true;
		});
	};
}
