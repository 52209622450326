export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const CONTENT_JSON_URL = process.env.REACT_APP_CONTENT_JSON_URL || "";
export const BASE_URL = process.env.REACT_APP_BASE_URL || "/";
export const SENTRY_DSN_URL = process.env.REACT_APP_SENTRY_DSN_URL;
export const SHARE_URL = process.env.REACT_APP_SHARE_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const DEFAULT_CHIPS_STEP = 1;
export const SITE_URL = process.env.REACT_APP_SITE_URL || window.location.origin;
export const SHARE_PATH = process.env.REACT_APP_SHARE_PATH || "";
export const EMAIL_REGEXP = "\\S+@\\S+\\.\\S+";
export const PASSWORD_PATTERN =
	"^((?=.*?\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[\\-!@#$%^&*\\(\\)_+\\|~=`\\{\\}\\[\\]:\";'<>?,.\\/])).{8,}$";
export const DATE_FORMAT_BY_LOCALE = {
	en: "MMM-dd h:mma ZZZZ",
	fr: "dd MMM à HH:mm ZZZZ",
};
export const PASSWORD_REQUIREMENTS =
	"Password must include 8 characters, including 1 upper case character, 1 number and 1 special character";

export const CONTEST_ROUTES = [{path: "/contest/:id"}, {path: "/contest/:id/result"}];
export const FACEBOOK_ID = process.env.REACT_APP_FACEBOOK_APP_ID || false;
