import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, computed, makeAutoObservable, observable} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IGameplayStore} from "data/stores/gameplay/gameplay.store";
import {Bindings} from "data/constants/bindings";
import {ContestUtils} from "data/utils/contest_utils";
import {DateTime} from "luxon";
import {find, some} from "lodash";
import {DATE_FORMAT_BY_LOCALE} from "data/constants";
import type {IContest} from "data/types/contests";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import moment from "moment";
import type {IUserStore} from "data/stores/user/user.store";
import React from "react";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {ModalType, RequestState} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";

interface IControllerProps {
	contest: IContest;
}

export interface IContestBannerController extends ViewController<IControllerProps> {
	get i18n(): ILocalizationStore;
	get hasContest(): boolean;
	get isComplete(): boolean;
	get isLocked(): boolean;
	get isOpen(): boolean;
	get isComingSoon(): boolean;
	get contestURL(): string;
	get isDateVisible(): boolean;
	get isTimerVisible(): boolean;
	get contestButtonCopy(): string;
	get isEditable(): boolean;
	get lockDateString(): string;
	get buttonStyle(): SxProps<Theme>;
	get isShowLocalText(): boolean;
	get isLoad(): boolean;

	updateContest: (contest: IContest) => void;
	setContestComplete: () => void;
	moveToRegisterIfNeed: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
}

@injectable()
export class ContestBannerController implements IContestBannerController {
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable private _contest?: IContest;

	// private get hasQuestions() {
	// 	return Boolean(this._contest?.questions?.length);
	// }

	get buttonStyle() {
		if (this.isComplete || (this.isEditable && this._userStore.isAuthorized) || this.isLocked) {
			return {
				opacity: this.hasContest ? 1 : 0,
				transition: "opacity 1s",
				background: "#ffffff !important",
				color: "#092033 !important",
				border: "1px solid #092033",
				":hover": {
					color: "#FFFFFF",
				},
			};
		}
		return {
			opacity: this.hasContest ? 1 : 0,
			transition: "opacity 1s",
			background: "#4DC1EF",
			color: "#092033",
			":hover": {
				color: "#FFFFFF",
			},
		};
	}

	// private get allQuestionsComplete() {
	// 	return !!this._contest?.questions.every(QuestionUtils.isComplete);
	// }

	get hasContest() {
		return Boolean(this._contest);
	}

	get isShowLocalText() {
		if (!this._contest) {
			return false;
		}

		const startDate = moment();
		const timeEnd = moment(this._contest?.dateStart);
		const diff = timeEnd.diff(startDate);
		const diffDuration = moment.duration(diff);
		const checkForTime = (time: number) => (time >= 0 ? time : 0);

		return (
			ContestUtils.isLocked(this._contest) ||
			(checkForTime(diffDuration.days()) === 0 &&
				checkForTime(diffDuration.hours()) === 0 &&
				checkForTime(diffDuration.minutes()) === 0)
		);
	}

	get isLoad() {
		return this._requestState === RequestState.PENDING;
	}

	@computed get isComplete() {
		if (!this._contest) return false;
		return ContestUtils.isComplete(this._contest);
	}

	@computed get isLocked() {
		if (!this._contest) return false;
		return ContestUtils.isLocked(this._contest);
	}

	@computed get isOpen() {
		if (!this._contest) return false;
		return ContestUtils.isActive(this._contest);
	}

	@computed get isComingSoon() {
		if (!this._contest) return false;
		return ContestUtils.isScheduled(this._contest);
	}

	@computed get isEditable() {
		if (!this._gameplayStore.answeredContests) return false;
		return (
			this.isOpen &&
			!!this._gameplayStore.answeredContests.find(
				({contest}) => contest === this._contest?.id
			)
		);
	}

	@computed get contestButtonCopy() {
		if (this.isOpen) {
			if (this.isEditable && this._userStore.isAuthorized) {
				return this.i18n.t("contest.edit_picks.button", "Edit Picks");
			}
			return this.i18n.t("contest.link.play_now", "Make picks");
		}

		if (this.isComingSoon) return this.i18n.t("contest.link.coming_soon", "Coming soon");
		if (this.isLocked) return this.i18n.t("contest.link.view_picks", "View picks");
		if (this.isComplete) return this.i18n.t("contest.link.review_results", "View results");
		return " ";
	}

	@computed get contestURL() {
		if (!this._contest) return "";
		const id = this._contest.id;
		return this.isComplete ? `/contest/${id}/result` : `/contest/${id}`;
	}

	@computed get isDateVisible() {
		if (!this._contest) return false;
		return ContestUtils.isLocksAfterOneDay(this._contest);
	}

	@computed get isTimerVisible() {
		if (!this._contest) return false;
		return some([
			ContestUtils.isLocksInOneDay(this._contest),
			ContestUtils.endsInLessThanOneDay(this._contest),
			ContestUtils.endsInMoreThanOneDay(this._contest),
		]);
	}

	@computed get lockDateString() {
		if (!this._contest) return "";

		const locale = this.i18n.locale;

		const timeFormat =
			find(DATE_FORMAT_BY_LOCALE, (_, lang) => locale.includes(lang)) ||
			DATE_FORMAT_BY_LOCALE.en;

		const dt = DateTime.fromISO(this._contest.dateStart, {locale});
		return dt.toFormat(timeFormat);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.GameplayStore) private readonly _gameplayStore: IGameplayStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action setContestComplete = () => {
		this._gameplayStore.setContestComplete(this._contest?.id ?? 0);
	};

	@action updateContest = (contest: IContest) => {
		this._contest = contest;
	};

	@action init(param: IControllerProps): void {
		this.updateContest(param.contest);
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: error.message,
		});
	};

	@action moveToRegisterIfNeed = (event: React.SyntheticEvent<HTMLButtonElement>) => {
		if (!this._userStore.isAuthorized && this.isEditable) {
			this._requestState = RequestState.PENDING;
			this._userStore
				.goToSSO()
				.catch(this.onError)
				.finally(() => {
					this._requestState = RequestState.SUCCESS;
				});
			event.preventDefault();
		}
	};

	dispose(): void {
		return;
	}
}
