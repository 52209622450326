import {IUser} from "data/stores/user/user.store";

export enum ConnextraType {
	HOME = "f2p_ppapredictor_homepage",
	REGISTRATION_START = "f2p_ppapredictor_regstart",
	REGISTRATION_CONFIRM = "f2p_ppapredictor_regconfirm",
	LOGIN = "f2p_ppapredictor_loggedin",
	PICK_START = "f2p_ppapredictor_pickstart",
	PICK_CONFIRM = "f2p_ppapredictor_pickconfirmation",
	SHARE = "f2p_ppapredictor_shareclick",
}

const CONNEXTRA_URL = `https://zz.connextra.com/dcs/tagController/tag/e33bc2a221e0/`;

export const createConnextraScriptTag = (type: ConnextraType, user?: IUser) => {
	console.log(type);

	const elementName = "script";
	const SCRIPT_ID = "connextra-tagging";
	document.getElementById(SCRIPT_ID)?.remove();
	const src = `${CONNEXTRA_URL}${type}`;

	const url = new URL(src);

	if (user?.id) {
		url.searchParams.append("AccountID", `${user.id}`);
	}

	const script = document.createElement(elementName);
	script.id = SCRIPT_ID;
	script.src = url.toString();
	script.async = true;
	script.defer = true;

	const firstJS = document.getElementsByTagName(elementName)[0];
	firstJS.parentNode!.insertBefore(script, firstJS);

	return script;
};
