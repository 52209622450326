import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import type {IGameplayStore} from "data/stores/gameplay/gameplay.store";
import {ModalType} from "data/enums";
import {AxiosError} from "axios";
import type {IModalsStore} from "data/stores/modals/modals.store";

interface IParams {
	contestId: number;
	isResult: boolean;
	message: string;
}

export interface IContestShareController extends ViewController<IParams> {
	get i18n(): ILocalizationStore;
	get user(): IUser;
	get shareImageUrl(): string;

	openShareModal: () => void;
}

@injectable()
export class ContestShareController implements IContestShareController {
	@observable private _imgUrl: string = "";
	@observable private _isResult: boolean = true;
	@observable private _message: string = "";
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.GameplayStore) private readonly _gameplayStore: IGameplayStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	async init(params: IParams) {
		this._isResult = params.isResult;
		this._message = params.message;
		if (params.isResult) {
			try {
				const img = await this._gameplayStore.requestShareImg({
					contestId: params.contestId,
				});

				runInAction(() => {
					this._imgUrl = img;
				});
			} catch (err) {
				this._onCatchError(err);
			}
		}
	}

	get user() {
		return this._userStore.user!;
	}

	get shareImageUrl() {
		return this._imgUrl;
	}

	dispose(): void {
		return;
	}

	@action _onCatchError(err: unknown) {
		const error = err as AxiosError;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: error.message,
		});
	}

	@action openShareModal = () => {
		this._modalsStore.showModal(ModalType.SHARE, {
			message: this._message,
			isResult: this._isResult,
			shareImageUrl: this._imgUrl,
		});
	};
}
