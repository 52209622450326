import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable} from "inversify";

export interface IRangeSliderController extends ViewController {
	get min(): number;

	onDragStart: () => void;

	onDragEnd: () => void;
}

@injectable()
export class RangeSliderController implements IRangeSliderController {
	constructor() {
		makeAutoObservable(this);
	}

	public onDragStart = () => {
		setTimeout(() => {
			this.onDragEnd();
		}, 500);
	};

	public onDragEnd = () => {
		return;
	};

	get min(): number {
		return 0;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
