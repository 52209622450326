import {isEqual, filter, map} from "lodash";
import {IQuestion, IQuestionWithAnswers} from "data/types/contests";

type TOptionStatus = "default" | "correct" | "wascorrect" | "incorrect" | "selected" | "disabled";

type TOptionStatusKey =
	| "DEFAULT"
	| "CORRECT"
	| "WAS_CORRECT"
	| "INCORRECT"
	| "SELECTED"
	| "DISABLED";

export enum QuestionsStatus {
	SCHEDULED = "scheduled",
	LOCKED = "locked",
	COMPLETE = "completed",
}

export const OptionStatus: Record<TOptionStatusKey, TOptionStatus> = {
	DEFAULT: "default",
	CORRECT: "correct",
	WAS_CORRECT: "wascorrect",
	INCORRECT: "incorrect",
	SELECTED: "selected",
	DISABLED: "disabled",
};

const {SCHEDULED, LOCKED, COMPLETE} = QuestionsStatus;

export abstract class QuestionUtils {
	static questionStatus = QuestionsStatus;
	static optionStatus = OptionStatus;

	static isScheduled = ({status}: IQuestion) => isEqual(SCHEDULED, status);

	static isLocked = ({status}: IQuestion) => isEqual(LOCKED, status);

	static isComplete = ({status}: IQuestion) => isEqual(COMPLETE, status);

	static notComplete = ({status}: IQuestion) => !isEqual(COMPLETE, status);

	static getPointsValue = (question: IQuestion) => {
		return question.cost;
	};

	static getAnswersAndCorrectOptions = (question: IQuestionWithAnswers) => {
		return {
			answersId: question.answer?.optionId,
			correctOptionIds: map(filter(question.options, "isCorrect"), "id"),
		};
	};

	static getOptionStatus = (question: IQuestionWithAnswers, optionID: number) => {
		const {answersId, correctOptionIds} = QuestionUtils.getAnswersAndCorrectOptions(question);

		const isAnswered = answersId === optionID;
		const isCorrect = correctOptionIds.includes(optionID);

		if (QuestionUtils.isComplete(question)) {
			return QuestionUtils.getOptionStatusForCompleteState(isAnswered, isCorrect);
		}

		if (QuestionUtils.isLocked(question)) {
			return QuestionUtils.getOptionStatusForLockedState(isAnswered);
		}

		return QuestionUtils.getOptionStatusForScheduledState(isAnswered);
	};

	static hasCorrectAnswer(question: IQuestionWithAnswers) {
		if (question.options === null) {
			return false;
		}
		return question.options.some(({id}) =>
			isEqual(QuestionUtils.getOptionStatus(question, id), OptionStatus.CORRECT)
		);
	}

	static hasIncorrectAnswer(question: IQuestionWithAnswers) {
		if (question.options === null) {
			return false;
		}
		return question.options.some(({id}) =>
			isEqual(QuestionUtils.getOptionStatus(question, id), OptionStatus.INCORRECT)
		);
	}
	static hasImageInOptions(question: IQuestionWithAnswers) {
		if (question.options === null) {
			return false;
		}
		return question.options.some(({image}) => !!image);
	}

	private static getOptionStatusForCompleteState(isAnswered: boolean, isCorrect: boolean) {
		if (isAnswered) {
			return isCorrect ? OptionStatus.CORRECT : OptionStatus.INCORRECT;
		}

		return isCorrect ? OptionStatus.WAS_CORRECT : OptionStatus.DISABLED;
	}

	private static getOptionStatusForLockedState(isAnswered: boolean) {
		return isAnswered ? OptionStatus.SELECTED : OptionStatus.DISABLED;
	}

	private static getOptionStatusForScheduledState(isAnswered: boolean) {
		return isAnswered ? OptionStatus.SELECTED : OptionStatus.DEFAULT;
	}
}
