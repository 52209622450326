import {action, computed, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IModalsStore, IModalPayload} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import type {IGameplayStore} from "data/stores/gameplay/gameplay.store";
import {AxiosError} from "axios";
import {IContest} from "data/types/contests";
import {find} from "lodash";
import moment from "moment";
import {ContestUtils} from "data/utils/contest_utils";

interface IParams {
	contestId: number;
}

export interface IModalShareController extends ViewController<IParams> {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get modalContent(): IModalPayload | null;
	get user(): IUser;
	get shareImageUrl(): string | undefined;
	get isResult(): boolean;
	get message(): string | undefined;
	get currentContest(): IContest | null;
	get endDate(): string;
	get isShowLocalText(): boolean;

	close: () => void;
}

@injectable()
export class ModalShareController implements IModalShareController {
	@observable private _contestId: number = 0;
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.GameplayStore) private readonly _gameplayStore: IGameplayStore
	) {
		makeAutoObservable(this);
	}

	init(params: IParams) {
		this._contestId = params.contestId;
	}

	@action _onCatchError(err: unknown) {
		const error = err as AxiosError;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: error.message,
		});
	}

	get shareImageUrl() {
		return this.modalContent?.shareImageUrl;
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.SHARE;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	get user() {
		return this._userStore.user!;
	}

	get isResult() {
		return Boolean(this.modalContent?.isResult);
	}

	get message() {
		return this.modalContent?.message;
	}

	@computed get currentContest() {
		return find(this._gameplayStore.allContests, {id: this._contestId}) || null;
	}

	get endDate() {
		const startDate = moment();
		const timeEnd = moment(this.currentContest?.dateStart);
		const diff = timeEnd.diff(startDate);
		const diffDuration = moment.duration(diff);
		const checkForTime = (time: number) => (time >= 0 ? time : 0);

		return `${checkForTime(diffDuration.days())} days  ${checkForTime(
			diffDuration.hours()
		)} hours ${checkForTime(diffDuration.minutes())} minutes`;
	}

	get isShowLocalText() {
		if (!this.currentContest) {
			return false;
		}

		const startDate = moment();
		const timeEnd = moment(this.currentContest?.dateStart);
		const diff = timeEnd.diff(startDate);
		const diffDuration = moment.duration(diff);
		const checkForTime = (time: number) => (time >= 0 ? time : 0);

		return (
			ContestUtils.isLocked(this.currentContest) ||
			(checkForTime(diffDuration.days()) === 0 &&
				checkForTime(diffDuration.hours()) === 0 &&
				checkForTime(diffDuration.minutes()) === 0)
		);
	}

	close = () => {
		this._modalsStore.hideModal();
	};
}
