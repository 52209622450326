import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

export interface ICountry {
	name: string;
	code: string;
}

export interface ICountriesStore {
	fetchCountries(): Promise<void>;
	list: ICountry[];
}

@injectable()
export class CountriesStore implements ICountriesStore {
	@observable private _list: ICountry[] = [];

	get list() {
		return this._list;
	}

	constructor(
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.LocalizationStore) private readonly _localizationStore: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	@action async fetchCountries() {
		const locale = (this._localizationStore.locale || "en-us").toLowerCase();
		const {data} = await this._jsonProvider.countries(locale);

		runInAction(() => {
			this._list = data;
		});
	}
}
