import React from "react";
import {observer} from "mobx-react";
import {Button, Modal, Stack} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ReactComponent as IconInfo} from "assets/img/icons/Info.svg";
import {ReactComponent as IconClose} from "assets/img/icons/close.svg";
import {Exist} from "views/components/exist/exist.component";
import type {IModalConfirmController} from "views/components/modals/modal_confirm/modal_confirm.controller";

const ModalContent = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	outline: none;
	max-width: 600px;
	width: 100%;
	background: #fff;
	padding: 20px;
	border-radius: 4px;
	text-align: center;

	@media screen and (max-width: 640px) {
		max-width: unset;
		left: 24px;
		right: 24px;
		width: auto;
		transform: translateY(-50%);
	}
`;

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	svg {
		fill: #4dc1ef;
	}
`;

const Icon = styled.div`
	width: 80px;

	svg {
		width: 100%;
		height: auto;
		fill: #c20000;
	}
`;

const Title = styled.h2`
	font-size: 24px;
	font-style: normal;
	font-weight: 900;
	line-height: 24px;
	text-transform: uppercase;
	margin: 20px 0;
`;

const Description = styled.div`
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	margin: 20px 0;
`;

const WrapperForButtons = styled(Stack)`
	button {
		padding: 14px 16px;
		max-width: 300px;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px;
		color: #0c2b44;
		background: #4dc1ef;
		border-radius: 0;
	}
`;

export const ModalConfirm: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalConfirmController>(
		Bindings.ModalConfirmController
	);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<CloseBlock>
					<Button sx={{minWidth: 0}} onClick={close}>
						<IconClose />
					</Button>
				</CloseBlock>
				<Icon>
					<IconInfo />
				</Icon>
				<Title>{i18n.t(modalContent?.title ?? "modal.confirm.title", "Confirm")}</Title>
				<Exist when={modalContent?.message}>
					<Description>{modalContent?.message}</Description>
				</Exist>
				<WrapperForButtons direction="row" gap={3}>
					<Button fullWidth variant="contained" onClick={close}>
						{i18n.t("modal.confirm.cancel_button", "Cancel")}
					</Button>
					<Exist when={modalContent?.callback}>
						<Button fullWidth variant="contained" onClick={modalContent?.callback}>
							{i18n.t("modal.confirm.confirm_button", "Confirm")}
						</Button>
					</Exist>
				</WrapperForButtons>
			</ModalContent>
		</Modal>
	);
});
