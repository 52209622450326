import styled from "@emotion/styled";
import {Container, Link, useMediaQuery} from "@mui/material";
import {useViewController} from "data/services/locator/locator_hook.service";
import {observer} from "mobx-react";
import React from "react";
import {ReactComponent as IconFB} from "assets/img/icons/footerFb.svg";
import {ReactComponent as IconInstagram} from "assets/img/icons/instagram.svg";
import {ReactComponent as IconPoweredGs} from "assets/img/icons/LogoGS.svg";
import {ReactComponent as IconTiktok} from "assets/img/icons/tiktok.svg";
import {ReactComponent as IconTwitter} from "assets/img/icons/twitterWhite.svg";
import {ReactComponent as IconYouTube} from "assets/img/icons/youtube.svg";
import {ReactComponent as IconPoweredGsMobile} from "assets/img/IconPoweredGsMobile.svg";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import {Bindings} from "data/constants/bindings";
import Logo from "assets/img/ppa-logo-white.png";
import {Exist} from "views/components/exist/exist.component";
import {NavLink} from "react-router-dom";

const mobileViewQuery = `max-width: 860px`;

const Background = styled.div`
	background: #0c2b44;
`;

const Wrapper = styled.footer`
	padding: 54px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #fff;

	font-family: var(--fontFamilyBase);
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	@media (max-width: 960px) {
		flex-direction: column;
		text-align: center;
		font-size: 14px;
		line-height: 20px;
		padding: 20px;
	}
`;

const SiteInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media (max-width: 960px) {
		align-items: center;
	}
`;

const Socials = styled(SiteInfo)`
	display: flex;
	align-items: flex-end;
	flex-direction: inherit;

	p {
		text-align: left;
		padding-left: 8px;
		color: var(--textColor4);
		text-transform: uppercase;
	}

	ul {
		display: flex;
		margin-top: 8px;
		align-items: flex-end;

		li {
			padding: 0 8px;
			transition: color 0.5s ease-in-out;
			cursor: pointer;
			display: flex;
			align-items: flex-end;

			a {
				display: flex;
				align-items: flex-end;
			}
			&:last-child {
				padding-right: 0;
			}

			svg {
				width: 22px;
				height: 22px;
			}

			&:hover {
				color: var(--textColor5);
			}
		}
	}

	@media (max-width: 960px) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 10px;
		padding: 24px 10px 0;
		flex-flow: column;
		width: 100%;

		p {
			font-size: 16px;
			line-height: 20px;
			padding: 0;
		}
	}
`;

const Follow = styled.div`
	display: flex;
	flex-direction: column;

	p {
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px;
		text-transform: inherit;

		@media (max-width: 840px) {
			text-align: center;
		}
	}

	svg {
		fill: #ffffff;
	}
`;

const GeniusSportsLink = styled.a`
	transition: color 0.5s ease-in-out;
	display: flex;
	justify-content: flex-end;
	margin-left: 32px;

	&:hover {
		color: var(--textColor5);
	}

	@media (max-width: 960px) {
		margin-top: 20px;
		margin-left: 0;
	}
`;

const Copyrights = styled.div`
	color: #ffffff;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;

	@media (max-width: 840px) {
		margin-top: 20px;
	}
`;

const UsefulLing = styled.div`
	margin-top: 13px;
	display: flex;
	gap: 74px;

	@media (max-width: 840px) {
		gap: 50px;
		justify-content: center;
		margin-top: 50px;
	}

	@media (max-width: 640px) {
		gap: 30px;
		justify-content: center;
	}

	a {
		color: var(--textColor4);
	}
`;

export const LinkStyled = styled(Link)`
	text-decoration: none;
	outline: none;
	background-position: 0 100%;
	background-repeat: no-repeat;
	transition: background-size 0.5s ease 0s;
	background-size: 0 1px;
	cursor: pointer;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;

	&:hover {
		background-size: 100% 1px;
	}

	&[target="_blank"] {
		background-size: 100% 1px;

		&:hover {
			background-size: 0 1px;
		}
	}
`;

const GameLogoLink = styled(NavLink)`
	display: flex;
	justify-content: center;
	flex-direction: column;

	img {
		display: block;
		width: 120px;
	}

	p {
		color: #ffffff;
		font-weight: 700;
		letter-spacing: 6px;
		font-size: 18px;
		margin: 5px 0 10px 0;
	}
`;

const links: Record<string, string> = {
	terms: "/help/terms-of-use",
	privacy: "https://pickleballdev.net/privacy-policy",
	twitter: "https://twitter.com/PPAtour/status/1761896027769151614",
	facebook: "https://www.facebook.com/OfficialPPATour/",
	instagram: "https://www.instagram.com/ppatour/",
	youtube: "https://www.youtube.com/@PPAtour",
	tickToc: "https://www.tiktok.com/@officialppatour",
};

export const FooterMenu: React.FC = observer(() => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
	return (
		<UsefulLing>
			<div>
				<LinkStyled target="_blank" rel="noopener noreferrer" href={links.terms}>
					{i18n.t("footer.terms_of_use", "Terms & Conditions")}
				</LinkStyled>
			</div>
			<div>
				<LinkStyled target="_blank" rel="noopener noreferrer" href={links.privacy}>
					{i18n.t("footer.privacy_policy", "Privacy Policy")}{" "}
				</LinkStyled>
			</div>
		</UsefulLing>
	);
});

export const Footer: React.FC = observer(() => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
	const isMobileView = useMediaQuery(`(${mobileViewQuery})`);

	return (
		<Background>
			<Container>
				<Wrapper>
					<SiteInfo>
						<GameLogoLink to={"/"}>
							<img src={Logo} alt={"PPA"} />
							<p>PICK 'EM</p>
						</GameLogoLink>
						<Exist when={!isMobileView}>
							<Copyrights>
								{i18n.t("footer.copyright", "© 2024 PPA All copyrights reserved")}
							</Copyrights>
						</Exist>
						<Exist when={!isMobileView}>
							<FooterMenu />
						</Exist>
					</SiteInfo>

					<Socials>
						<Follow>
							<p>{i18n.t("footer.follow", "Follow us on")}</p>
							<ul>
								<li>
									<a
										href={links.instagram}
										target="_blank"
										rel="noopener noreferrer">
										<IconInstagram />
									</a>
								</li>
								<li>
									<a
										href={links.tickToc}
										target="_blank"
										rel="noopener noreferrer">
										<IconTiktok />
									</a>
								</li>
								<li>
									<a
										href={links.facebook}
										target="_blank"
										rel="noopener noreferrer">
										<IconFB />
									</a>
								</li>
								<li>
									<a
										href={links.youtube}
										target="_blank"
										rel="noopener noreferrer">
										<IconYouTube />
									</a>
								</li>
								<li>
									<a
										href={links.twitter}
										target="_blank"
										rel="noopener noreferrer">
										<IconTwitter />
									</a>
								</li>
							</ul>
						</Follow>
						<Exist when={isMobileView}>
							<FooterMenu />
						</Exist>
						<GeniusSportsLink
							href="https://geniussports.com/"
							target="_blank"
							rel="noopener noreferrer">
							<Exist when={isMobileView}>
								<IconPoweredGsMobile />
							</Exist>
							<Exist when={!isMobileView}>
								<IconPoweredGs />
							</Exist>
						</GeniusSportsLink>
						<Exist when={isMobileView}>
							<Copyrights>
								{i18n.t("footer.copyright", "© 2024 PPA All copyrights reserved")}
							</Copyrights>
						</Exist>
					</Socials>
				</Wrapper>
			</Container>
		</Background>
	);
});
