import {useViewController} from "data/services/locator/locator_hook.service";
import {observer} from "mobx-react";
import React, {Fragment, useEffect} from "react";
import {NavLink, Outlet, useParams} from "react-router-dom";
import {ContestsDropdown} from "views/components/contest/contests_dropdown/contests_dropdown.component";
import {Container, PageHeader} from "views/components/structure/structure.component";
import {IContestController} from "views/controllers/contest/contest.controller";
import {Box, Stack, useMediaQuery, Avatar} from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import {Exist} from "views/components/exist/exist.component";
import styled from "@emotion/styled";
import {ContestUtils} from "data/utils/contest_utils";
import {IContestRouteParams} from "data/types/global";
import {Bindings} from "data/constants/bindings";
import {ContestShare} from "views/components/contest/contest_share/contest_share.component";
// import ShareIcon from "@mui/icons-material/Share";
import {last} from "lodash";
import {IContest, IQuestionWithAnswers} from "data/types/contests";

const mobileViewQuery = `max-width: 860px`;

const HeaderButtonsBox = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;

	a {
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px;
		&:hover {
			text-decoration: underline;
		}
	}

	@media (max-width: 640px) {
		justify-content: flex-start;
	}
`;

const SContainer = styled(Container)`
	z-index: 6;
	margin-top: -50px;

	@media (max-width: 870px) {
		padding: 0;
		margin-top: 0;
	}
`;

const PointsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background: #4dc1ef;
	border-radius: 90px;
	color: #0c2b44;
	padding: 7px 10px;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
`;

const checkIsShowSharing = (
	currentContest: IContest | null,
	contestQuestions: IQuestionWithAnswers[] | undefined
) =>
	currentContest
		? ContestUtils.isComplete(currentContest) &&
		  !!currentContest.questions.length &&
		  contestQuestions?.find((question) => question.answer)
		: false;

export const ContestRoot = observer(() => {
	const contestID = Number(useParams<IContestRouteParams>().id);
	const controller = useViewController<IContestController>(Bindings.ContestController, {
		contestID,
	});
	const isMobileView = useMediaQuery(`(${mobileViewQuery})`);

	const {currentContest, allContests, updateContestID, i18n, contestQuestions} = controller;
	const isComplete = currentContest ? ContestUtils.isComplete(currentContest) : false;
	const isShareAvailable = checkIsShowSharing(currentContest, contestQuestions);
	const pageName = last(window.location.pathname.split("/"));

	const correctAnswers = contestQuestions?.filter(
		(question) => question?.answer?.isCorrect
	).length;
	const shareText = i18n.t(
		"share.twitter.contest_result_text",
		`I scored {{scored}} on PPA Pick '{{contest_name}}'s tournament! Think you can beat me next time? Sign up and find out!`,
		{
			scored: `${correctAnswers || 0}/${contestQuestions?.length || 0}`,
			contest_name: currentContest?.name,
		}
	);

	useEffect(() => {
		void updateContestID(contestID);
	}, [contestID, updateContestID]);

	return (
		<Fragment>
			<Exist when={!isMobileView || pageName === "result"}>
				<PageHeader>
					<Container>
						<h1 style={{textTransform: "uppercase"}}>{currentContest?.name ?? ""}</h1>
						<Box sx={{height: 6}} />
						<HeaderButtonsBox>
							<Stack direction="row" alignItems="center" gap={1.5}>
								<Exist when={!isComplete}>
									<NavLink to="/help">
										{i18n.t("contest.how_to_play.title", "How to play")}
									</NavLink>
								</Exist>
								<Exist when={isComplete}>
									<PointsWrapper>
										{controller.totalPoints}{" "}
										{i18n.t("contest.points.label", "Pts")}
									</PointsWrapper>
								</Exist>
							</Stack>
							<Exist when={isShareAvailable}>
								<NavLink to={`/rankings/${contestID}`}>
									<Avatar
										sx={{
											backgroundColor: "#4DC1EF",
											height: "32px",
											width: "32px",
										}}>
										<EmojiEventsIcon
											sx={{
												backgroundColor: "#4DC1EF",
												color: "#092033",
												height: "24px",
												width: "24px",
											}}
										/>
									</Avatar>
								</NavLink>
								<ContestShare
									message={shareText}
									component={
										<PointsWrapper>
											{i18n.t("contest.share.label", "Share")}
										</PointsWrapper>
										// <Avatar
										// 	sx={{
										// 		backgroundColor: "#4DC1EF",
										// 		height: "32px",
										// 		width: "32px",
										// 	}}>
										// 	<ShareIcon
										// 		sx={{
										// 			backgroundColor: "#4DC1EF",
										// 			color: "#092033",
										// 			height: "24px",
										// 			width: "24px",
										// 		}}
										// 	/>
										// </Avatar>
									}
									isResult={
										currentContest
											? ContestUtils.isComplete(currentContest)
											: false
									}
								/>
							</Exist>
						</HeaderButtonsBox>
					</Container>
				</PageHeader>
			</Exist>
			<SContainer>
				<ContestsDropdown currentContest={currentContest} allContests={allContests} />
			</SContainer>
			<Outlet context={controller} />
		</Fragment>
	);
});
