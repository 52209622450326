/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {ThemeOptions} from "@mui/material/styles";

export const buttonsTheme: ThemeOptions = {
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: "700",
					fontSize: "16px",
					borderRadius: "0",
					":hover": {
						":disabled": {
							boxShadow:
								"0px 0.1px 0.3px rgba(28, 28, 30, 0.1), 0px 1px 2px rgba(28, 28, 30, 0.2)",
						},
					},
					":disabled": {
						background: "grey",
						color: "rgba(17, 17, 17, 0.4)",
						cursor: "default",
					},
				},
				containedPrimary: {
					color: "#FFF",
					":hover": {
						opacity: ".5",
					},
					":disabled": {
						background: "grey",
						color: "rgba(17, 17, 17, 0.4)",
						cursor: "default",
					},
				},
				outlinedPrimary: {
					borderColor: "blue",
					color: "blue",
					":hover": {
						opacity: ".5",
					},
					":disabled": {
						border: "none",
					},
				},
				containedSecondary: {
					color: "#018A8A",
					":hover": {
						background: "#FFF",
					},
					":disabled": {
						background: "grey",
						color: "rgba(17, 17, 17, 0.4)",
						cursor: "default",
					},
				},
				containedError: {
					background: "red",
					color: "#FFF",
				},
			},
			variants: [
				{
					props: {className: "byClassName"},
					style: {
						background: "yellow",
						color: "#000",
						":hover": {
							opacity: ".5",
						},
						":disabled": {
							background: "grey",
							color: "rgba(17, 17, 17, 0.4)",
							cursor: "default",
						},
					},
				},
			],
		},
	},
};
