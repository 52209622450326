import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {
	type IMyAccountController,
	MyAccountController,
} from "views/pages/my_account/my_account.controller";
import {type ILoginController, LoginController} from "views/pages/login/login.controller";
import {API_URL, CONTENT_JSON_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {RoundsStore, type IRoundsStore} from "data/stores/rounds/rounds.store";
import {PlayersStore, type IPlayersStore} from "data/stores/players/players.store";
import {SquadsStore, type ISquadsStore} from "data/stores/squads/squads.store";
import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secret_gate/secret_gate.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {
	type ILiveScoreController,
	LiveScoreController,
} from "views/controllers/live_score/live_score.controller";
import {ChecksumStore, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type IUserApiProvider, UserApiProvider} from "data/providers/api/user.api.provider";
import {
	type IPasswordApiProvider,
	PasswordApiProvider,
} from "data/providers/api/password.api.provider";
import {
	type ISessionController,
	SessionController,
} from "views/components/session/session.controller";
import {
	BootstrapController,
	type IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";
import {type IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {
	type IModalErrorController,
	ModalErrorController,
} from "views/components/modals/modal_error/modal_error.controller";
import {
	type IModalConfirmController,
	ModalConfirmController,
} from "views/components/modals/modal_confirm/modal_confirm.controller";
import {
	type ILocalizationController,
	LocalizationController,
} from "views/controllers/localization/localization.controller";
import {HeaderController, type IHeaderController} from "views/components/header/header.controller";
import {MyPicksController, type IMyPicksController} from "views/pages/my_picks/my_picks.controller";
import {GameplayStore, type IGameplayStore} from "data/stores/gameplay/gameplay.store";
import {
	GameplayAPIProvider,
	type IGameplayApiProvider,
} from "data/providers/api/gameplay.api.provider";
import {
	ContestBannerController,
	type IContestBannerController,
} from "views/components/contest/contest_banner/contest_banner.contoller";
import {
	ContestQuestionCardController,
	type IContestQuestionCardController,
} from "views/components/contest/contest_question_card/contest_question_card.controller";
import {
	IRangeSliderController,
	RangeSliderController,
} from "views/components/RangeSlider/rangeSlider.controller";
import {
	ContestController,
	type IContestController,
} from "views/controllers/contest/contest.controller";
import {
	ContestRootController,
	IContestRootController,
} from "views/components/contest/contest_root/contest_root.controller";
import {
	BackdoorController,
	type IBackdoorController,
} from "views/pages/backdoor/backdoor.controller";
import {
	type IStandingsController,
	StandingsController,
} from "views/components/standings/standings.controller";
import {
	type IStandingsApiProvider,
	StandingsApiProvider,
} from "data/providers/api/standings.api.provider";
import {IStandingsStore, StandingsStore} from "data/stores/standings/standings.store";
import {
	type IContestShareController,
	ContestShareController,
} from "views/components/contest/contest_share/contest_share.controller";
import {
	type IModalShareController,
	ModalShareController,
} from "views/components/modals/modal_share/modal_share.controller";
import {
	type IModalUserRegistrationController,
	ModalUserRegistrationController,
} from "views/components/modals/modal_user_registration/modal_user_registration.controller";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IUserApiProvider>(Bindings.UserApiProvider).to(UserApiProvider);
	bind<IPasswordApiProvider>(Bindings.PasswordApiProvider).to(PasswordApiProvider);
	bind<IGameplayApiProvider>(Bindings.GameplayApiProvider).to(GameplayAPIProvider);
	bind<IStandingsApiProvider>(Bindings.StandingsApiProvider).to(StandingsApiProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IRoundsStore>(Bindings.RoundsStore).to(RoundsStore).inSingletonScope();
	bind<IPlayersStore>(Bindings.PlayersStore).to(PlayersStore).inSingletonScope();
	bind<ISquadsStore>(Bindings.SquadsStore).to(SquadsStore).inSingletonScope();

	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore);
	bind<IStaticContentStore>(Bindings.StaticContentStore).to(StaticContentStore);

	bind<IChecksumStore>(Bindings.ChecksumStore).to(ChecksumStore);
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<IGameplayStore>(Bindings.GameplayStore).to(GameplayStore).inSingletonScope();
	bind<IStandingsStore>(Bindings.StandingsStore).to(StandingsStore);
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<ILoginController>(Bindings.LoginController).to(LoginController);
	bind<IMyAccountController>(Bindings.MyAccountController).to(MyAccountController);
	bind<ISecretGateController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ILiveScoreController>(Bindings.LiveScoreController).to(LiveScoreController);
	bind<ISessionController>(Bindings.SessionController).to(SessionController);
	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);
	bind<IModalErrorController>(Bindings.ModalErrorController).to(ModalErrorController);
	bind<IModalConfirmController>(Bindings.ModalConfirmController).to(ModalConfirmController);
	bind<ILocalizationController>(Bindings.LocalizationController).to(LocalizationController);
	bind<IHeaderController>(Bindings.HeaderController).to(HeaderController);
	bind<IMyPicksController>(Bindings.MyPicksController).to(MyPicksController);
	bind<IContestBannerController>(Bindings.ContestBannerController).to(ContestBannerController);
	bind<IContestQuestionCardController>(Bindings.ContestQuestionCardController).to(
		ContestQuestionCardController
	);
	bind<IRangeSliderController>(Bindings.RangeSliderController).to(RangeSliderController);
	bind<IContestController>(Bindings.ContestController).to(ContestController);
	bind<IContestRootController>(Bindings.ContestRootController).to(ContestRootController);
	bind<IBackdoorController>(Bindings.BackdoorController).to(BackdoorController);
	bind<IStandingsController>(Bindings.StandingsController).to(StandingsController);
	bind<IContestShareController>(Bindings.ContestShareController).to(ContestShareController);
	bind<IModalShareController>(Bindings.ModalShareController).to(ModalShareController);
	bind<IModalUserRegistrationController>(Bindings.ModalUserRegistrationController).to(
		ModalUserRegistrationController
	);
});
