import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IHttpClientService} from "data/services/http";
import {AxiosResponse} from "axios";
import {IApiResponse} from "data/types/common";
import {IAnswer, IAnsweredContest} from "data/types/contests";

export interface ISaveRequests {
	variables: {
		locale: string;
		contest: number;
		answers: {
			questionId: number;
			optionId: number | null;
		}[];
	};
	response: IApiResponse<{questionId: number; optionId: number}>;
}

export interface ISubmitRequests {
	variables: IPayloadSubmitAnswers;
	response: IApiResponse<{questionId: number; optionId: number}>;
}

export interface IGetRequests {
	variables: {locale: string; contestId: number};
	response: IApiResponse<{
		answers: IAnswer[];
	}>;
}

export interface IGetSubmittedRequests {
	variables: {locale: string};
	response: IAnsweredContest[];
}

export interface IPayloadSubmitAnswers {
	contest: number;
	answers: {
		questionId: number;
		optionId: number | null;
	}[];
}

export interface ISharingImage {
	contestId: number;
}

export interface IGameplayApiProvider {
	getAnswers: (
		variables: IGetRequests["variables"]
	) => Promise<AxiosResponse<IGetRequests["response"]>>;

	getAnsweredContests: () => Promise<AxiosResponse<TGetAnswersResponse>>;

	submitAnswers: (
		variables: IPayloadSubmitAnswers
	) => Promise<AxiosResponse<ISaveRequests["response"]>>;

	getShareImg: (variables: ISharingImage) => Promise<AxiosResponse<TShareImgResponse>>;
}

export type TGetAnswersResponse = IApiResponse<{contests: IAnsweredContest[]}>;
export type TShareImgResponse = IApiResponse<{image: string}>;

@injectable()
export class GameplayAPIProvider implements IGameplayApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	getAnswers = ({contestId}: IGetRequests["variables"]) =>
		this._http.get<IGetRequests["response"]>(`predictor/answer/${contestId}`);

	getAnsweredContests = () => this._http.get<TGetAnswersResponse>(`predictor/answer`);

	submitAnswers = (
		params: IPayloadSubmitAnswers
	): Promise<AxiosResponse<ISubmitRequests["response"]>> =>
		this._http.post<ISubmitRequests["response"]>(`predictor/answer`, params);

	getShareImg = ({contestId}: ISharingImage) =>
		this._http.get<TShareImgResponse>(`predictor/sharing-image/${contestId}`);
}
