import "reflect-metadata";

import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";

import React, {Suspense} from "react";
import reportWebVitals from "./reportWebVitals";
import {RootRoutes, SecretGateRoutes} from "routes";
import {init, Integrations, BrowserTracing, Replay} from "@sentry/react";
import {
	createBrowserRouter,
	createRoutesFromElements,
	Outlet,
	Route,
	RouterProvider,
} from "react-router-dom";
import {createRoot} from "react-dom/client";
import {
	DIContainer,
	services,
	providers,
	stores,
	controllers,
} from "data/services/locator/locator_container.service";
import {InjectionProvider} from "data/services/locator/locator_provider.service";
import {StyledEngineProvider} from "@mui/material/styles";
import {ThemeProvider} from "@mui/material";
import {BASE_URL, SENTRY_DSN_URL} from "data/constants";
import {PagePreloader} from "views/components/preloader";
import {SecretGateController} from "views/controllers/secret_gate/secret_gate.controller";
import {Session} from "views/components/session/session.component";
import {Bootstrap} from "views/components/bootstrap/bootstrap.component";
import {ModalError} from "views/components/modals/modal_error/modal_error.component";
import {ModalConfirm} from "views/components/modals/modal_confirm/modal_confirm.component";
import {emptyTheme} from "assets/theming/theme";
import {ModalShare} from "views/components/modals/modal_share/modal_share.component";
import {ModalUserRegistration} from "views/components/modals/modal_user_registration/modal_user_registration.component";

if (SENTRY_DSN_URL) {
	init({
		dsn: SENTRY_DSN_URL,
		integrations: [
			new BrowserTracing(),
			new Replay(),
			new Integrations.Breadcrumbs({
				console: false,
			}),
		],
		environment: process.env.REACT_APP_SENTRY_ENV || "development",
		allowUrls: [
			"*.f2p.media.geniussports.com",
			// TODO Add Prod domain before first release
		],
		ignoreErrors: [
			"Abort due to cancellation of share",
			"AbortError: Share canceled",
			/Network Error/i,
			/Fetch Error/i,
		],
		denyUrls: [
			"quantcast",
			"xsca",
			// browser's extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/^moz-extension:\/\//i,
		],
		sampleRate: 0.1,
	});
}

const root = document.getElementById("root");

if (!root) {
	throw Error("Root element was not found");
}

DIContainer.load(services, providers, stores, controllers);

const Hocs = () => (
	<StyledEngineProvider injectFirst>
		<ThemeProvider theme={emptyTheme}>
			<InjectionProvider container={DIContainer}>
				<Bootstrap>
					<Suspense fallback={<PagePreloader />}>
						<Session>
							<Outlet />
						</Session>
					</Suspense>
					<ModalError />
					<ModalConfirm />
					<ModalShare />
					<ModalUserRegistration />
				</Bootstrap>
			</InjectionProvider>
		</ThemeProvider>
	</StyledEngineProvider>
);

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path={"/"} element={<Hocs />}>
			{SecretGateController.IS_SECRET_PASSED ? RootRoutes : SecretGateRoutes}
		</Route>
	),
	{
		basename: BASE_URL,
	}
);

createRoot(root).render(
	<React.StrictMode>
		<InjectionProvider container={DIContainer}>
			<RouterProvider router={router} />
		</InjectionProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
