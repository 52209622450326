import React from "react";
import {observer} from "mobx-react";
import {Button, FormControl, Modal, Stack} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ReactComponent as IconInfo} from "assets/img/icons/Info.svg";
import {ReactComponent as IconClose} from "assets/img/icons/close.svg";
import type {IModalUserRegistrationController} from "views/components/modals/modal_user_registration/modal_user_registration.controller";
import {ButtonPrimary} from "views/components/buttons/buttons.component";
import {Checkbox, Input, Label} from "views/components/form";

const ModalContent = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	outline: none;
	max-width: 600px;
	width: 100%;
	background: #fff;
	padding: 20px;
	border-radius: 4px;
	text-align: center;

	@media screen and (max-width: 640px) {
		max-width: unset;
		left: 24px;
		right: 24px;
		width: auto;
		transform: translateY(-50%);
	}
`;

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	svg {
		fill: #4dc1ef;
	}
`;

const Icon = styled.div`
	width: 80px;

	svg {
		width: 100%;
		height: auto;
		fill: #c20000;
	}
	@media (max-width: 960px) {
		margin-top: -40px;
	}
`;

const Title = styled.h2`
	font-size: 20px;
	font-style: normal;
	font-weight: 900;
	line-height: 20px;
	text-transform: uppercase;
`;

const Description = styled.div`
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;

const FormWrapper = styled.div`
	padding: 0 20px;

	label {
		margin-left: 10px;

		a {
			font-weight: 900;
		}
	}
`;

const ButtonPrimaryStyled = styled(ButtonPrimary)`
	min-width: 300px;

	&:disabled {
		background: #c7c7cc !important;
		color: #fff !important;
	}
`;

const FormElementTitle = styled.div`
	width: 100%;
	text-align: left;
	color: #0c2b44;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	margin-bottom: 10px;
`;

const FormControlStyled = styled(FormControl)`
	margin-bottom: 20px;
`;

export const ModalUserRegistration: React.FC = observer(() => {
	const {
		isOpen,
		i18n,
		close,
		onChangeOpt,
		onChangeTerms,
		isSelectOptIn,
		isSelectTerms,
		onSubmit,
		lockSubmit,
		displayName,
		changeDisplayName,
		isNotificationsEnabled,
		onChangeIsNotification,
	} = useViewController<IModalUserRegistrationController>(
		Bindings.ModalUserRegistrationController
	);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<CloseBlock>
					<Button sx={{minWidth: 0}} onClick={close}>
						<IconClose />
					</Button>
				</CloseBlock>
				<Icon>
					<IconInfo />
				</Icon>
				<Title>{i18n.t("modal.user_registration.title", "Registration")}</Title>
				<Description
					dangerouslySetInnerHTML={{
						__html: i18n.t(
							"modal.user_registration.description",
							"Please complete registration"
						),
					}}
				/>
				<FormWrapper>
					<FormElementTitle>
						{i18n.t(
							"modal.user_registration.title.display_name",
							"Enter your display name"
						)}
					</FormElementTitle>
					<FormControlStyled fullWidth>
						<Input
							value={displayName}
							label="Display name"
							name="displayName"
							type="text"
							placeholder="Display name"
							required={true}
							onChange={changeDisplayName}
						/>
					</FormControlStyled>
					<Stack flexDirection={"initial"} margin={"10px 0"}>
						<Checkbox
							name="isNotificationsEnabled"
							id="isNotificationsEnabled"
							checked={isNotificationsEnabled}
							onChange={onChangeIsNotification}
						/>
						<Label htmlFor="isNotificationsEnabled">
							{i18n.t(
								"modal.user_registration.notifications.label",
								"Select to receive results and reminder emails"
							)}
						</Label>
					</Stack>
					<Stack flexDirection={"initial"} margin={"10px 0"}>
						<Checkbox
							name="optIn"
							id="optIn"
							checked={isSelectOptIn}
							onChange={onChangeOpt}
						/>
						<Label htmlFor="optIn">
							{i18n.t(
								"modal.user_registration.marketing_emails.label",
								"Select to be informed about exclusive offers, promotions and products from PPA."
							)}
						</Label>
					</Stack>
					<Stack flexDirection={"initial"}>
						<Checkbox
							name="terms"
							id="terms"
							required={true}
							checked={isSelectTerms}
							onChange={onChangeTerms}
						/>
						<Label
							htmlFor="terms"
							dangerouslySetInnerHTML={{
								__html: i18n.t(
									"modal.user_registration.terms.label",
									"I accept the <a href='https://pickleball.com/terms-of-use' target='_blank'>Terms and Conditions</a>"
								),
							}}
						/>
					</Stack>
				</FormWrapper>

				<ButtonPrimaryStyled variant="contained" onClick={onSubmit} disabled={lockSubmit}>
					{i18n.t("modal.user_registration.send_button", "Send")}
				</ButtonPrimaryStyled>
			</ModalContent>
		</Modal>
	);
});
